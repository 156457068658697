import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import logo from '../../images/quill-pen.png'

function NotFound() {
    return(
      <div className="d-flex align-items-center vh-100" style={{marginLeft: "50%"}}>
         <div>
             <h1 className="display-1 fw-bold">
             <Link to={`/`} style={{color: "white"}}>
               <img title="Desk-chair icon created by Freepik https://www.freepik.com/" src={logo} style={{width: "10%"}} />
             </Link> 404</h1>
             <p className="fs-3"> <span className="text-danger">Ups! </span>
              nie ma takiej strony.

             </p>
         </div>
     </div>
    )
}

export default (NotFound)
