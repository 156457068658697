import React, {Component} from 'react'
import {Link} from 'react-router-dom'

class Navbar extends Component {
  render(){
    return(
      <nav className="nav nav-masthead justify-content-center">
        <Link to={`teksty`} className="nav-link fw-bold py-1 px-0">Teksty</Link>
        <Link to={`o-mnie`} className="nav-link fw-bold py-1 px-0">O mnie</Link>
        <Link to={`kontakt`} className="nav-link fw-bold py-1 px-0">Kontakt</Link>
      </nav>
    )
  }
}

export default (Navbar)
