import React, {Component} from 'react'


import Home from './components/Home'
import About from './components/menu/About'
import Texts from './components/menu/Texts'
import Contact from './components/menu/Contact'
import NotFound from './components/menu/NotFound'
import TagManager from 'react-gtm-module'
import CookieConsent from "react-cookie-consent"

//stories for test-opowiadan
import Stefania from './components/stories/Stefania'

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'


function App() {

  const tagManagerArgs = {
    gtmId: 'GTM-K2K65S7'
}

TagManager.initialize(tagManagerArgs);

  return (
      <>
      <Router>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route exact path="/teksty" element={<Texts/>}/>
          <Route exact path="/o-mnie" element={<About/>}/>
          <Route exact path="/kontakt" element={<Contact/>}/>
          <Route exact path="/test-opowiadan" element={<Stefania/>}/>
          <Route  path="*" element={<NotFound/>}/>
        </Routes>
      </Router>
      <CookieConsent
        debug={false}
        buttonText={'Zgoda'}
      >
        Ta strona korzysta z ciasteczek, aby świadczyć usługi na najwyższym poziomie.
        Dalsze korzystanie oznacza, że zgadzasz się na ich użycie.
      </CookieConsent>
      </>
  );
}

export default App;
