import React, {Component} from 'react'
import Navbar from './Navbar'
import logo from '../images/quill-pen.png'
import {Link} from 'react-router-dom'

class Main extends Component {
  render(){
    return(
      <main className="px-3">
        <h1><i>Lubię pisać</i> <img title="Desk-chair icon created by Freepik https://www.freepik.com/" src={logo} style={{width: "10%"}} /></h1>
        <p className="lead">to miejsce na moje historie...</p>
        <p className="lead">
          <Link to={`teksty`} className="btn btn-lg btn-secondary fw-bold border-white bg-white">Wejdź</Link>
        </p>
      </main>
    )
  }
}

export default (Main)
