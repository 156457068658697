import React, {Component} from 'react'
import Modal from 'react-modal'
import picture from '../images/story-view.jpg'
import Story from './Story'
import {  FaWindowClose, FaBookReader, FaPlay, FaPause } from 'react-icons/fa'
import { Blocks } from  'react-loader-spinner'
import { useState, useRef } from "react"
import ReactAudioPlayer from 'react-audio-player'
import { Tooltip } from 'react-tooltip'



class PostCard extends Component {
  constructor(){
    super();

    this.state = {
      apiData: [],
      isOpen: false,
      storyContent: '',
      story: '',
      loading: true,
      isPlaying: false,
      audio: new Audio('https://ls-api.pl/sound/Dyspozytorka/file.m4a')
    }

    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount(){
    let url ='https://ls-api.pl/lubie-pisac/stories'
    fetch(url, {
      //crossDomain: true,
      headers: new Headers( {
        'Content-Type':'application/json;charset=utf-8',
        'X-Bearer-Token': '123'
      })
    })
   .then(res => res.json())
   .then((data) => this.setState({apiData: data, loading: false}) )
  }

  componentWillUnmount(){
    this.state.audio.pause();
  }

toggleModal(index){
  let data = this.state.apiData
  let record = data[index];

  if(record){
      this.setState({storyTitle: record.title});
      this.setState({story: record});
  }

  this.setState({isOpen: !this.state.isOpen})
}

  render(){
    //let storyFile = require('../components/stories' + '');
    const { apiData } = this.state;
    apiData.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.creationDate) - new Date(a.creationDate);
    });

    const customStyles = {
      content: {
        backgroundColor: '#212529',
        opacity: '999999',
        height:'100%',
        position: 'static'
      },
      overlay: {
        backgroundColor: 'black'
      }
      };
        const url = 'https://ls-api.pl/sound/Dyspozytorka/file.m4a';
      const audio = new Audio(url);
      audio.loop = false;

    return(
      <div className="row">
         {this.state.loading && <Blocks/>}
         {
           apiData.map((item, i) => (
             <div className="col-md-4  mt-3" key={i}>
              <div className="card shadow-sm" style={{backgroundColor: "#0A0A0A"}}>
                {item.new && <span className="badge badge-light position-absolute mt-2">Nowość!</span>}
                <img src={picture} className="bd-placeholder-img card-img-top" width="100%" height="225" />
                 <div className="card-body">
                   <p className="card-text text-white" style={{textTransform:"uppercase"}}>{item.title}</p>
                   <div className="">
                     <div className="btn-group" style={{display: "block"}}>
                       <button onClick={() => this.toggleModal(i)}  type="button" className="btn btn-block btn-outline-secondary"><FaBookReader/></button>
                       <br/><br/>
                       {item.soundFile &&
                         <ReactAudioPlayer
                            src={url}
                            controls
                          />
                       }
                     </div>
                   </div>
                 </div>
              </div>
             </div>
           ))
         }
         <Modal
          isOpen={this.state.isOpen}
          onRequestClose={this.toggleModal}
          contentLabel="My dialog"
          ariaHideApp={false}
          style={customStyles}
          >

          <div>
            <a onClick={this.toggleModal} style={{float: "right", cursor: "pointer"}}><FaWindowClose size={50}/></a>
          </div>
          <Story url={this.state.story.url}/>

        </Modal>
     </div>
    );
  }
}

export default (PostCard)
