import React, {Component} from 'react'

class Kurier extends Component {
  render(){
    return(
      <div>
      <h3 className="text-center mt-3 mb-3"><i>Prolog</i></h3>
      <p>
    To wszystko co zobaczyłem wcześniej nie zapowiadało takiego zakończenia.
</p>

<p>
    Cyniczny, złośliwy uśmiech, kropelki śliny wyciekjące z tych przeraźliwie rozdziawionych ust.
</p>

<p>
    Chyba upadłem. Chyba uderzyłem głową o podłogę.
</p>

<p>
    Brak pewności do nazwania tego, co właśnie mnie spotkało mieszał się z pewnością do ogarniającego mnie lęku.
</p>

<p>
    Tak chyba wygląda koniec.
</p>
      <h3 className="text-center mt-3 mb-3"><i>Dorosłe życie</i></h3>
      <p>
    Odkąd wyszedłem z rodzinnego domu to poczucie wolności przepełniało mnie na wskroś. To była radość w najczystszej postaci. Niesamowita ulga!
    Wiedziałem, że teraz jestem zdany tylko na siebie. Sam decyduję co będę robić dziś, a co zrobię jutro. Sam na wszystko też
    muszę sobie zarobić. Jeśli nie zarobię, to nie będę mieć. Proste!
</p>

<p>
    Mógłbym coś wykombinować, nielegalnie coś zamieszać, po prostu mógłbym coś ukraść. Pewnie przeżyłbym trochę stresu, ale  efekt końcowy byłby zadowalający.
    Ale nie. Nie ja.
    To akurat moim rodzicom się udało, przekazać tę myśl, iż w życiu nigdy nie jest łatwo. Tak też traktuję wszystko, co napotyka mnie na codzień.
    Nie mogę osiągać swoich celów bez wykazania choćby krzty wysiłku lub bez poniesienia pewnych kosztów.
</p>

<p>
    Moi starzy próbowali na mnie wszelkich sposobów kindersztuby. Surowy ojciec, a do tego uległa, poddana matka, to recepta na idealne wychowanie samca.
    Wszystkie tęgie głowy tego świata, które w swoich bublowatych podręcznikach, dedykowanych rodzicom, przekazują tego rodzaju myśl,
    zapomniały albo świadomie przeoczyły wyjątek, iż nie każdy wychowanek ulegnie tego rodzaju sztuce. Takim wyjątkiem byłem właśnie ja.
</p>

<p>
    Z racji sporych warunków fizycznych od dziecka wyróżniałem się na tle swoich rówieśników. Wyglądałem na kilka lat starszego i to właśnie oni, kilka lat starsi,
    wciągnęli mnie do swojego środowiska. Pierwsze papierosy, pierwsze dziewczyny pojawiły się w moim życiu, gdy miałem sto osiemdziesiąt centymetrów wzrostu.
    Fakt, iż miałem wtedy dopiero jedenaście lat interesował wyłącznie moją matkę, która o wszystkich moich wybrykach pospiesznie donosiła ojcu.
</p>

<p>
    Słowne tarcia, wygrażania jeden drugiemu, to był standard. Mama jedynie przymykała drzwi od pokoju i z różańcem w ręku prosiła niebiosa o opamiętanie.
    Często się zastanawiałem, czy ona faktycznie wierzyła, że przesuwanie tych koralików może wywołać jakiś piorun z nieba, który trafi jednego z nas, bo
    tylko to mogło przerwać nasze zapędy.
</p>

<p>
    Każda wymiana zdań kończyła się nowym śladem ojcowskiego paska na moich pośladkach. A gdy miałem już naście lat, to tylko kolejną drzazgą wypadającą z
    futryny naszych wejściowych drzwi. Sąsiedzi przywykli do tej atmosfery i nie reagowali na częste trzaskania.
</p>

<p>
    Odetchnienia szukałem, a właściwe zawsze znajdowałem, wśród podwórkowej braci. Na nich mogłem liczyć i tylko oni dawali mi spokój i
    poczucie szczęścia. W takich okolicznościach udało mi się dobrnąć do osiemnastki, a właściwie do momentu ukończenia szkoły.
</p>

<p>
    Tego dnia, gdy odbierałem ostatnie świadectwo ojciec jak powiedział tak zrobił. Gdy tylko wróciłem do domu przed drzwiami czekała na mnie spora torba
    wypchana moimi rzeczami, a na klamce kartka z napisem:
</p>

<p>
    "Gratulacje z okazji ukończenia szkoły. Powodzenia w dorosłym życiu. Rodzice."
</p>
      <h3 className="text-center mt-3 mb-3"><i>Stały klient</i></h3>

<p>
    - Dwa duże zestawy obiadowe wraz z zupą dnia - spokojnym, stonowanym głosem mężczyna złożył zamówienie.
</p>

<p>
    - Dobrze. Czy coś do picia? - odparł głos w słuchawce.
</p>

<p>
    Kobieta spoglądała na jego usta. Czy spróbuje coś więcej powiedzeć? A może nagle zacznie krzyczeć? Nie. Była pewna.
    W tych wąskich ramionkach i w tym wątłym ciele jest tylko strach. Może trochę paniki, a na pewno ogromny lęk. Jak zwykła
    mawiać to lęk robi robotę. Zawsze.
</p>

<p>
    Mężczyzna po chwili milczenia podziękował grzecznie i odłożyl słuchawkę. Ona tylko spojrzała na niego i wybuchła śmiechem.
    Śmierdzące drobinki jej śliny wylatywały z przerw w niepełnym uzębieniu. Gdy złapała go za włosy, a potem przytuliła do swojej piersi
    on tylko patrzył jak na podłodze zaczęła rozrastać się nowo utworzona kałuża moczu. Jego moczu.
</p>

<p>
    Jedzenie przywieziono  szybko. Ona nie lubiła czekać i dlatego mężczyzna wciąż zamawiał posiki z tego samego miejsca. Tam kierowcy byli solidni i nigdy
    nie zdarzyło się, aby któryś z nich nie dotarł na czas.
    Jak zwykle obie porcje kurczaka zjadła sama. Resztkę ryżu oddała mężczyznie wraz z pojemnikiem na sos, którego wiele nie zostało.
</p>

<p>
    Po posiłkach ona szła na drzemkę, a on siadał przed wyłączonym monitorem i przez długi czas wpatrywał się w swoje odbicie.
    Patrzył na siebie i nie mógł uwierzyć, że to wszystko spotkało właśnie jego.
</p>

<p>
    Najpierw ten nieszczęśliwy wypadek samochodowy, w którym stracił rodziców i sprawność od pasa w dół.
    Potem krótki okres wspaniałego, wręcz bajkowego małżeństwa, zakończony przez katastrofę samolotu, którym akurat leciała jego żona.
    A na koniec teściowa, która dodatkowo potęgowała falę nieszczęść w jego życiu.
</p>

<p>
    Po stokroć zadawane pytanie dlaczego i po stokroć brak odpowiedzi.
</p>

<p>
    - Synku, chodź do mnie na chwileczkę. - usłyszał jej głos z drugiego pokoju.
</p>

<p>
    - A zapomniałam, nie możesz chodzić! - odpowiedziała sobie sama dodając do tego salwy śmiechu.
</p>

<p>
    - Kochanie, wiesz, że to wszystko dla ciebie - mężczyzna wyszeptał.
</p>
      <h3 className="text-center mt-3 mb-3"><i>Praca</i></h3>
      <p>
    Gdy zbiegałem wtedy ze schodów w naszym budynku, zastanawiałem się, czy lepiej spalić ten liścik od rodziców i wrzucić im
    na wycieraczkę,
    czy może wykorzystać go do jakieś poniżającej zabawy. Jednak po chwili namysłu uznałem, że skoro skończyłem szkołę i moja rodzina
    pozbawiła mnie dachu nad głową to muszę mieć swoją godność i nie po drodze mi takie gówniarskie gierki.
</p>

<p>
    Na piwo zabrał mnie Zielony. To swój człowiek i tylko tacy ludzie byli wokół mnie. Oni nigdy mnie samego nie zostawili. Po kilku albo nawet
    kilkunastu browarach zaprowadzili, a może i zanieśli, do Zielonego do domu. U niego posiedziałem kilka dni, aż powiedział,
    że jego rodzice mają jakieś fochy i powininem sobie czegoś poszukać.
</p>

<p>
    - Dzięki za pomoc, będę się tu w okolicy kręcić. - powiedziałem Zielonemu na odchodne,
</p>

<p>
    Łażąc bez celu po osiedlu zacząłem się nad sobą zastanawiać. Bardzo szybko przyszła refleksja nakazująca poszukiwanie jakiegoś zajęcia.
</p>

<p>
    W życiu podejmowałem się różnych prac. Od zbioru truskawek, po
    mycie samochodów, kończąc na roznoszeniu ulotek reklamujących
    agencje towarzyskie. Rodzice nigdy nie dawali mi czegoś takiego jak kieszonkowe, a wzięcie reszty z drobnych zakupów
    kończyło się zwykle odebraniem bilonu z jednoczesnym otrzymaniem surowej kary.
</p>

<p>
    Etos pracy był czymś co miałem we krwi, w genach i wszędzie tam gdzie tylko można. Szacunek należał się zawsze ludziom
    pracującym, a leserzy byli traktowani jak nieroby, którymi należy gardzić. Taką myśl mi wpojono i to w sobie pielęgnowałem.
</p>

<p>
    Doskonale wiedziałem, że po liceum rynki pracy nie staną przede mną otworem. Samo ogólne wykształcenie stanowi tak naprawdę pewne przetarcie przed
    ogromem konkretnej wiedzy, która aż wylewa się z uniwersyteckich annałów. Kiedy już cała ta myśl ułożyła się w mojej głowie, to głośno parsknąłem.
</p>

<p>
    - To ten rodzaj pieprzenia mojego ojca, widzę, że tak łatwo się tego nie pozbędę. - wyszeptałem pod nosem.
</p>

<p>
    W Kielcach oferty pracy dla ludzi z moimi kwalifikacjami znajdowały się na dworcu. Wiem jak to brzmi, ale faktycznie tak było.
    Przed wejściem, na bocznej ścianie wisiała wielka tablica pełna ogłoszeń:
</p>

<p>
    "Pizzeria u Ostrego zatrudni pomoc kuchenną. Szczegóły w lokalu."
</p>

<p>
    "Murarz poszukiwany!"
</p>

<p>
    "Nie boisz się psów? Poszukuję opiekuna do parki moich dwóch rottweilerów. Preferowany kontakt telefoniczny."
</p>

<p>
    I to ostatnie było czymś dla mnie.
</p>

<p>
    Pomyślałem wtedy o wszystkich złamanych przeze mnie nosach i rozwalonych wargach.
    Dwa pieski? Na mnie jednego i to jeszcze na smyczy? Prościej być już nie może! Wybrałem numer podany na ogłoszeniu i po krótkiej rozmowie
    byłem już w autobusie, który wiózł mnie na Barwinek.
</p>

<p>
    Podany przez rozmówcę adres spisałem do telefonu więc nie mogłem się pomylić. Wątpliwość przyszła uzasadniona, bo pod wskazanym numerem
    znajdował się niemalże pałac. Ogromny budynek w malowniczym ogrodzie. Posesja położona była w głębi terenu dlatego, kiedy tylko zadzwoniłem do domofonu
    zdałem sobię sprawę, że czeka mnie jeszcze spory spacer.
</p>

<p>
    Właścicelem psów okazał się dystyngowany, starszy pan. Jeśli określiłbym go tylko mianem miłego człowieka, to pewnie trochę bym go obraził.
    Przywitał mnie z otwartą dłonią, twarzą pełną uśmiechu i zapachem kawy, którą przygotował specjalnie dla mnie. Usiedliśmy przy stoliku w ogrodzie.
    Nie znam się na roślinach, kwiatach i innych bluszczach, ale czułem, że nie jeden miłośnik florystki bardzo zazdrości mi obecnego położenia.
</p>

<p>
    Po krótkiej rozmowie prześliśmy od razu do konkretów. Miałem otrzymywać sto złotych za każdy spacer z psami.
    Właścicel pokazał, gdzie znajduje się buda, a sam pojechał do pracy.
</p>

<p>
    - Stój! Nie gryź! Auu! Pomocy! Ratunku - wykrzykiwałem w niebogłosy.
</p>

<p>
    Dość szybko przestałem być wyprowadzaczem psów. Wtedy zrozumiałem, że nigdy więcej tych wszarzy!
</p>

<p>
    Za pierwszy i jednocześnie
    ostatni spacer dostałem trzysta złotych.
    Sto za prawie pełny spacer, a dwieście
    za straty moralne w postaci pogryzień.
</p>

<p>
    - Ten stary dziad niech się cieszy, że nie poszedłem z tym na policję. - pomyślałem.
</p>

<p>
    Po chwili dodając:
</p>

<p>
    - Gdzie na policję?! Co ja wygaduję! Przecież zawsze i wszędzie policja...
</p>

<p>
    Trzeba trzymać się podwórkowych zasad.
</p>

<p>
    Gdy powrotnym autobusem wracałem do centrum zdałem sobie sprawę, że wiózł mnie ten sam kierowca, z którym jechałem wcześniej. Pewnie jadł na pętli obiad i pił gorącą herbatę, gdy ja odganiałem się od tych
    agresywnych sierściuchów. Kurs oczywiście był pod dworzec kolejowy, bo znów chiałem dostać się pod tę samą tablicę z ogłoszeniami.
</p>
<p>
    Niestety nie udało nam się wjechać w pełni na przystanek, bo jakiś kurier swoim samochodem zajął pół autobusowej zatoki. Numer telefonu
    na karoserii wraz z ogłoszeniem o poszukiwaniu nowych pracowników  spowodował uśmiech na mojej twarzy i pozwolił odważnie udać się na pobliską
    noclegownię.
</p>

      <h3 className="text-center mt-3 mb-3"><i>Rodzina</i></h3>

<p>
    Na obcą mi kobietę mówiłem mamo ponieważ ja już nie miałem swojej,
    a żona zawsze powtarzała, że jej mama jest także moją, bo kocha nas tak samo. Wierzyłem w te bujdy do czasu jej wypadku.
</p>

<p>
    Żyliśmy wyłącznie z mojej renty. Ja zanim zostałem kaleką dość dobrze zarabiałem więc świadczenie, które mi przyznano było nie najniższe i dało się z tego wyżyć.
    Moja żona musiała zrezygnować ze swojej pracy, aby móc się mną opiekować. Mama nigdy nie pracowała. Po ślubie ze swoim mężem od razu zajmowała się domem i było tak do jego śmierci, a potem my wzięliśmy ją do siebie.
    Słowo praca było jej znane jedynie z krzyżówek, które namiętnie rozwiązywała.
</p>

<p>
    Moje relacje z teściową na początku były nawet przyzwoite. Nie wchodziliśmy sobie w drogę. To była obopólna tolerancja z domieszką sympatii.
    Nasz małżeństwo natomiast nie miało swoich pełnych atrybutów na co na początku bardzo
    narzekałem, a potem już się do tego przyzwyczaiłem. Wszystkie swoje potrzeby i fantazje musiałem schować
    do szuflady. Wiedziałem, że moja żona jest szczęsliwa, więc i ja byłem.
</p>

<p>
    Kiedy to Ewa postanowiła pojechać na rozmowę z lekarzami o mojej operacji, poczułem, że mam w niej prawdziwe wsparcie.
    Ja wtedy miałem ciężki okres, nie nadawałem się na żadne wyprawy, a tym bardziej zagraniczne. Zatem, aby nie stracić szansy na zabieg to właśnie moja żona musiała zameldować się w
    klinice w Paryżu, żeby uzupełnić odpowiednie dokumenty. Przy okazji mogła też dowiedzieć się więcej szczegółów.
</p>

<p>
    Pamiętam to przedpołudnie, gdy siedząc na balkonie wpatrywałem się w chłopców grających w piłkę pod naszym blokiem, a teściowa przeraźliwym wrzaskiem oznajmiła,
    iż wydarzył się wypadek samolotu, którym leciała Ewa. Wszystkie serwisy informacyjne o tym donosiły, bo na pokładzie znajdował się jakiś światowej sławy chirurg. Krzyczała, że  nikt nie przeżył katastrofy.
</p>

<p>
    Od tego dnia moje życie zmieniło się nieodwracalnie. I nie mam tu na myśli faktu, iż wcześnie zostałem wdowcem.
</p>

      <h3 className="text-center mt-3 mb-3"><i>Powołanie</i></h3>

<p>
    Do "Szybcy i niezawodni" przyjęli mnie po pierwszej rozmowie. Dostałem samochód, telefon i pensję cztery tysiące złotych.
    Tyle mi wystarczało na wynajem mieszkania i jakieś jedzenie. Nie mogłem narzekać.
</p>

<p>
    Na początku rozwoziłem drobne przesyłki. Było ich sporo więc o ośmiogodzinnej pracy bardzo szybko zapomniałem.
    Po całym dniu wracałem padnięty bez sił na cokolwiek. Tuż pod moim blokiem był całonocny sklep w miarę dobrze zaopatrzony, gdzie robiłem zakupy. Nie jestem pewien czy czerstwe pieczywo i kilkanaście zupek chińskich można nazwać zakupami, ale to stanowiło skład
    jednorazowej reklamówki, z którą stamtąd wychodziłem.
</p>

<p>
    Praca kuriera wbrew pozorom nie wymaga wcale wyjątkowej siły mięśni, lecz pewnych zdolności komunikacyjnych. W gadce zawsze byłem dobry
    więc w nowej dla mnie branży odnalazłem się bardzo szybko. Pamiętam
    jak nie jeden raz zdarzyło mi się spóźnić lub przywieźć nieoczekiwaną przesyłkę. Rumieniec wstydu szybko przykrywałem szczerym uśmiechem i kilkoma
    grzecznościowymi słowami. Z każdym dniem czułem, że lubię tę robotę i całkiem nieźle mi ona wychodzi.
</p>

<p>
    - O! To znów mój ulubiony kurier! Dziękuję panie Dawidzie! - to standardowo słyszałem od klientów.
</p>

<p>
    Kiedy przepracowałem kilka tygodni wpadłem na pomysł, by komuś się pochwalić jak świetnie sobie radzę. Mam przecież samochód służbowy, dach nad głową.
    to zdecydowanie powody do dumy!
</p>

<p>
    Pierwszym wyborem był mój ojciec. To jemu całe życie coś udowadniałem i tej potrzeby nie udało mi się pozbyć nawet wtedy, gdy żyłem już na własny rachunek.
</p>

<p>
    Mama telefon odebrała po kilku sygnałach. Powiedziała, że ojciec śpi, odpoczywa po pracy. Krótkim chrząknięciem odpowiedziała na moje pytanie o zdrowie, ale
    mnie już nie zapytała o nic. Więcej w tej rozmowie milczeliśmy niż rozmawialiśmy. Rozłączyłem się i pomyślałem, jak bardzo ich nie znoszę, żeby nie powiedzieć
    nienawidzę!
</p>

<p>
    Musiałem jakoś odreagować.
</p>

<p>
    Zawitałem do osiedlowego sklepiku, by po chwili opuścić go z reklamówką pełną puszek z piwem.
    Kiedy byłem już pod wejściem na klatkę zadzwoniła moja komórka. To był tato.
    Zaskoczył mnie tym telefonem, a jeszcze bardziej prośbą o mój adres i informacją, że już do mnie jadą. Zrobiłem szybki zwrot i wróciłem
    po jakieś paluszki.
</p>

<p>
    Sam się sobie dziwiąc bardzo ucieszyłem się na widok rodziców. Mama przyniosła domowe ciasto, a tato silny ucisk dłoni, w czasie którego powiedział:
</p>

<p>
    - Dobrze cię widzieć synu!
</p>

<p>
    Na podwórku zawsze mnie uczono, że płaczą tylko baby albo słabiaki, ale po tych słowach taty, to gdzieś miałem te uliczne zasady i najmnocniej
    jak potrafiłem przytuliłem mojego ojca nie mogąc powstrzymać łez.
</p>

<p>
    Staliśmy tak wtuleni jeszcze kilka dobrych minut. Czułem mocną wodę kolońską zmieszaną z zapachem starszego człowieka.
    Zrozumiałem wtedy, że to już czas, aby wydorośleć i przestać się z nim kłócić.
</p>

<p>
    -  Tato już jest w sędziwym wieku i być może niewiele tych wspólnych uścisków nam zostało. - tak wtedy pomyślałem.
</p>

<p>
    Po zjedzeniu ciasta i wspólnej rozmowie, gdzie po raz pierwszy w życiu czułem, że nie mogę się z moimi starymi nagadać, wsiedliśmy w mój służbowy samochód, aby
    zobaczyć okolicę. Blok, w którym wynajmowałem mieszkanie znajdował się w urokliwej częśći Kielc.
</p>

<p>
    Po kilku godzinnym spotkaniu odwiozłem rodziców do mieszkania. Tego wieczoru już nie otwierałem kupionych wcześniej piw. Jutro czekał mnie przecież
    kolejny dzień pracy.
</p>
      <h3 className="text-center mt-3 mb-3"><i>Miłość</i></h3>

<p>
    Kiedy zostałem sam z teściową pod jednym dachem czułem, że nie wróży to nic dobrego.
</p>


<p>
    Kilkukrotnie, jeszcze jak żyla Ewa, mama dała mi odczuć, iż nie
    jestem jej wymarzonym zięciem. Zdarzyło mi się usłyszeć ich rozmowy, w których moja żona broniła mnie wobec najgorszych oskarżeń.
    Ewa po każdej takiej uszczypliwości ze strony teściowej prosiła mnie o wyrozumiałość, tłumaczyła jej nastawienie. Mówiła, że to przez samotność i tęsknotę za mężem. Bardzo kochałem
    moją żonę i dlatego wciąż zagryzałem zęby. Tak też robiłem po jej śmierci.
</p>

<p>
    - Zadzwoń po jedzenie, głodna jestem! - powiedziała mama.
</p>

<p>
    Jak zwykle sięgnąłem po telefon i wybrałem numer restauracji.
</p>

<p>
    - Dwa razy kurczak z ryżem? - zapytał głos po drugiej stronie.
</p>

<p>
    - Tak. Dokładnie, tak. - odparłem.
</p>

<p>
    Mój numer był już znany. Zamawiałem ten sam zestaw bardzo często stąd pracownicy restauracji doskonale wiedzieli
    po co dzwonię.
</p>

<p>
    - Mamo, przywiozą jedzenie za piętnaście minut. - powiedziałem teściowej po czym dostałem w głowę.
</p>

<p>
    - Ty pacanie jeden! Co tak długo! - powiedziała teściowa.
</p>

<p>
    - Na nic się nie nadajesz, nie potrafisz nawet szybko zamówić dla mnie jedzenia. Jak moja córka mogła związać się z takim patałachem.
</p>

<p>
    - Mamo, proszę, nie zaczynaj znowu. Zaraz przyjadą, zjesz sobie spokojnie i położysz się spać. Nie denerwuj się.
</p>

<p>
    Teściowa zepchnęła mnie z wózka dodatkowo depcząc mnie po palcach. Przywykłem do tego i nauczyłem się właściwie upadać.
</p>

<p>
    - A ty jesteś Bartek czy Bartłomiej? A może Bartosz co dusi grosz? - teściowa zadrwiła ze mnie.
</p>

<p>
    Poniżeń i obelg dostawałem  masę. Nie potrafiłem się jej przeciwstawić. Poniekąd byłem od niej zależny.
    Musiałem żyć z kimś kto mi pomoże. Nie mogłem wyrzucić jej z domu, bo wciąż w sercu miałem Ewę i trochę po cichu liczyłem,
    że mama w końcu się opamięta i zacznie mnie chociaż szanować.
</p>
      <h3 className="text-center mt-3 mb-3"><i>Odgłosy</i></h3>

<p>
    - Panie Dawidzie, leki proszę postawić w rogu. Bardzo pan miły. - powiedziała kobieta.
</p>

<p>
    Elżbieta Redlichowska była starszą, samotną osobą, która regularnie dostawała wielką paczkę z lekami od swojego syna.
    Lubiłem jej pomagać, bo miała piękny błysk w oku i była zawsze dla mnie bardzo miła.
</p>

<p>
    Pod płaszczykiem wychowanego w blokach chłopaka miałem skrywaną wrażliwość i empatię, co czasem samego mnie zawstydzało.
</p>

<p>
    - Teraz jeszcze ta wredna baba z tym synem na wózku. - pomyślałem.
</p>

<p>
    Zapukałem do drzwi. Cisza. Po chwili głośniej. Nagle dobiegł mmie odgłos uderzeń. Mialem wrażenie, że ktoś
    się bije. Tak, to zdecydowanie bijatyka.
</p>

<p>
    Usłyszałem przekręcany zamek i moim oczom ukazał się mężczyzna na wózku. Wydawało mi się, że
    dostrzegłem zaczerwienienie na jego twarzy.
</p>

<p>
    - Dzień dobry panie Bartoszu, przesyłka do pana jak co poniedziałek. - powiedziałem jednocześnie zerkając
    w głąb pokoju szukając jakiś śladów walki.
</p>

<p>
    - I jak co poniedziałek serdecznie panu dziękuję. Solidna firma z pana! - odparł inwalida uśmiechając się nieco.
</p>

<p>
    Gdy układałem w samochodzie nieodebrane paczki w pamięci miałem twarz pana Bartosza, który mimo życzliwego uśmiechu miał
    smutek w oczach. Pomyślałem wtedy, że baczniej muszę się przyjrzeć tej dziwnej rodzince.
</p>

<p>
    Do bazy wróciłem szybciej niż zwykle. W  domu pojawiłem się również wyjątkowo wcześniej więc pomyślałem, że warto odwiedzić rodziców.
</p>

<p>
    W trakcie drogi zadzwoniłem do mamy, która na wieść, że do nich jadę ucieszyła się i zachęciła bym się pospieszył, bo dziś na obiad moja ulubiona pomidorowa.
</p>

<p>
    - Cześć mamo! - rzuciłem na wejściu.
</p>

<p>
    Wydawało mi się, że mama miała zapłakane oczy, ale gdy wszedłem w głąb kuchni uznałem, że to szczypiący zapach cebuli, który mnie także
    zaczął doskwierać.
</p>

<p>
    - Co u ciebie słychać synku? Jak w pracy? - zapytała mama.
</p>

<p>
    - Nie mam powodów do narzekań. Lubię tę robotę, pieniądze swoje mam. Wszystko jest w jak najlepszym porządku. - odpowiedziałem.
</p>

<p>
    - To bardzo się cieszę. Zawsze się o ciebie bardzo bałam. Martwiłam się jak opuściłeś nasz dom.
</p>

<p>
    - Nie przejmuj się mamo, radzę sobie świetnie. Dobrze wtedy zrobiliście. To było jak wiadro zimnej wody, ale widać nie bylo na mnie innego sposobu.
    Dizś to nie ma żadnego znaczenia.
</p>

<p>
    Mama tylko spuściła wzrok i wzięła mnie za rękę. Poczułem jej spracowaną dłoń. Popatrzyłem na twarde palce, szorstką jak tak tarka skórę.
    Przypomniały mi się te wszystkie przykrości, które jej zadałem. Te wszystki kłótnie z ojcem, których ona była świadkiem. Zrobiło mi się jej żal.
</p>

<p>
    Dlaczego człowiek, aby osiągnąć pewną mądrość musi najpierw skrzywdzić bliskich? Dlaczego nie rodzimy się już z wykształconą psychiką na tyle, by móc
    odróżnić dobro od zła?
</p>

<p>
    Pomidorowa była wyborna. Zjadłem zupę, jeszcze trochę z mamą powspominaliśmy dawne czasy.
</p>
      <h3 className="text-center mt-3 mb-3"><i>Świadek</i></h3>

<p>
    Gdy przechodziłem korytarzem obok drzwi pana Bartosza, to znowu usłyszałem jakieś dziwne hałasy. Tego dnia nie było dla niego przesyłki aczkolwiek miałem
    się na baczności.
</p>

<p>
    Zastanawiałem się, czy wejść i zapytać co słychać, może przypadkowo przerwać jakieś zajście. Uznałem jednak, że pozostanę bierny i z uchem przy drzwiach
    poczekam na dalszy
    rozwój sytuacji.
</p>

<p>
    Nagle z głębi korytarza dobiegł mnie jakiś głos.
</p>

<p>
    - Poranek bez pana Dawida porankiem straconym! - to był pan Kucharski.
</p>

<p>
    - Jak dobrze pana widzieć panie Władysławie! - odpowiedziałem.
</p>

<p>
    Całe szczęście Władysław Kucharski był starszym, zniedołężniałym mężczyzną, który pewnie nie zauważył, że przed chwilą podsłuchiwałem.
    Mieszkał sam. Zdarzało się, że nim rozmawiałem, bo jak tylko słyszał jakiś szmer na korytarzu to od razu wychodził z mieszkania.
</p>

<p>
    - Tak, dziś mam sporo paczek u państwa w bloku. - zagaiłem.
</p>

<p>
    - Rozumiem, rozumiem. Ciężką ma pan pracę. Od samego rana musi pan te paczki dźwigać.
</p>

<p>
    - To prawda. Ale jakich wspaniałych ludzi spotykam. - uśmiechnąłem się i kiwnąłem głową na pożegnanie.
</p>

<p>
    Gdy zbiegałem po schodach do samochodu nagle zadzwonił mój telefon.
</p>

<p>
    - Synku, gdzie jesteś? Możesz do mnie przyjechać? - to był mama.
</p>

<p>
    Zaniepokoiłem się nieco, bo odniosłem wrażenie, jakby przed chwilą płakała. Odpowiedziałem krótko, że już jadę i wskoczyłem do samochodu.
</p>

<p>
    Do mieszkania rodziców dotarłem bardzo szybko. Mama leżała na podłodze. Miała rozciętą wargę, z której wylewała się stróżka krwi z zdobiąc kołnierzyk od jej koszuli.
</p>

<p>
    - Mamo! Co się stało?! - krzyknąłem.
</p>

<p>
    - Nic synku, nic. - odpowiedziała.
</p>

<p>
    - Kto ci to zrobił? Tato? - spytałem.
</p>

<p>
    - Nigdy nie waż się podnosić ręki na swojego ojca. - powiedziała, mocno ściskając mnie za rękę.
</p>

<p>
    Już wiedziałem co powinienem  zrobić.
</p>
      <h3 className="text-center mt-3 mb-3"><i>Cisza</i></h3>

<p>
    Już dawno o tym myślałem. Jak długo mam to jeszcze wytrzymać? Podjąłem decyzję więc muszę być konsekwentny. Muszę działać!
</p>

<p>
    Zadzwonił dzwonek do drzwi.
</p>

<p>
    - O, to pan! - powiedział mężczyzna na wózku.
</p>

<p>
    Odepchnąłem go na bok i wbiegłem w głąb pokoju.
</p>

<p>
    Ona siedziała akurat na fotelu, tyłem do mnie i nie zauważyła, że wszedłem. Zadałem pierwszy cios młotkiem.
    Od razu upadła na ziemię. Szybkim ruchem przewróciłem fotel na podłogę i doskoczyłem do jej grubego cielska.
</p>

<p>
    Zaczęliśmy się szarpać. Miała w sobie dużo siły, a ja szybko się zorientowałem, że trzonek młotka nie trafił bezpośrednio w potylicę.
    To było niesamowite uczucie. Biłem się z kobietą, a miałem wrażenie, że to rasowy sztangista albo inny siłacz. W ogóle nie mogłem dać jej rady.

</p>

<p>
    W końcu wyszarpałem się z jej uścisku. Wstałem! Mam przewagę. Teraz tylko muszę zlokalizować młotek. Jest! Znalazłem! Zaraz roztrzaskam jej ten głupi łeb!
</p>

<p>
    A potem stało się coś nieoczekiwanego. Pamiętam to rozlewające się ciepło po moim ciele. Pamiętam ten uśmiech, który przechodził przez jej zakrwawione wargi.
    Nagle nastała cisza i ciemność...
</p>



<p style={{marginTop: "100px"}}>
    Mama siedziała na fotelu pasażera i spoglądała w szybę.
</p>

<p>
    W naszym domu niejednokrotnie byłem świadkiem różnych scen. Tato miał zawsze władczy charakter, nie uznawał sprzeciwu. Zawsze trzeba było się
    podporządkować.
</p>

<p>
    Gdy wtedy zobaczyłem zakrwawioną twarz mojej mamy poczułem ukłócie w sercu. Od razu chciałem znaleźć ojca i wymierzyć mu sprawiedliwość.
    Słowa mamy jednak były pewnym hamulcem i dodały mi otrzeźwienia. Zachowałem spokój i poszukałem innego rozwiązania.
</p>

<p>
    Uznałem, że muszę mamę stamtąd zabrać.
</p>

<p>
    Pożegnałem się ze znajomymi, spakowałem swoje rzeczy i razem z mamą wyjechaliśmy z Kielc.
</p>

<p>
    Kolega, który przejął mój rewir powiedział, że ostatnio wydarzyło się tam coś okropego.
</p>

<p>
    Podobno pan Władysław nie żyje.
    Doszło do jakiejś bójki między nim, a matką pana Bartosza. Podobno została zaatakowana przez pana Kucharskiego,
    a pan Bartosz w ostatniej chwili stanął w jej obronie.
</p>

<p>
    Pomyślałem wtedy jak mocno musiał kochać swoją żonę, że ujął się za tak złą kobietą.
</p>


      </div>
    )
  }
}

export default (Kurier)
