import React, {Component} from 'react'

class Radiowiec extends Component {
  render(){
    return(
      <div>
      <h3 className="text-center mt-3 mb-3"><i>Głos</i></h3>
      <p>
    Sylwia często spóźnia się do pracy. Wstaje niby równo z budzikiem, nie włącza drzemki, ale i tak finalnie w biurze jest po czasie. Sylwia mieszka sama, wynajmuje małe mieszkanie na rogatkach Wrocławia.
    W domu ma schludnie, czysto, lubi porządek. Zamiast herbaty wybiera kawę, mocną bez cukru. Jest na starcie dorosłego życia. Wszystko, co zarobi odkłada. Jej marzeniem jest
    mieć własne mieszkanie. Sylwia do pracy dojeżdża starym, ale poczciwym samochodem znanej niemieckiej marki. Poczciwość tego samochodu polega na tym, że nie rujnuje jej budżetu i jedyne
    o co prosi to regularna dolewka płynu bogatego w minimum dziewiećdziesiąt pięć oktanów.
</p>

<p>
    Marek jest dumnym ojcem trójki dzieci i zawodowo zajmuje się prowadzeniem autobusu. Najtrudniej jest mu wstawać na poranno-wieczorne zmiany. Bo jak nazwać godzinę trzecią trzydzieści?
    Mimo piętnastu lat doświadczenia w tym zawodzie nadal nie pojmuje jak można o tak wczesnych porach czekać na przystanku na autobus. Przez lata zbudował sobie taki
    pogląd, że osoby, które potrzebują transportu publicznego o tak specyficznych godzinach powinny dostawać od państwa jakiś dodatek pieniężny, który by rekompensował
    absencję autobusów w tych godzinach. Według Marka, kierowca, który rozpoczyna pracę tak wcześnie jest na tyle niedospany, że tylko patrzeć jak ze zmęczenia wypada zza zakrętu lub
    uderza w przydrożny słup. Zatem lepiej jest zapłacić coś ludziom żeby nie korzystali z komunikacji publicznej niż narażać kierowców i pasażerów na niepotrzebne ryzyko.
</p>

<p>
    Karolina jest studentką. Lubi muzykę, śpiewa w scholi. W wolnych chwilach poświęca czas rodzeństwu. Dorywczo opiekuje się inwalidą, pomaga w jego codziennym życiu. Jeszcze nie ma
    pomysłu na siebie. Nie jest pewna, w którą stronę pójść. Często oddaje się zadumie i refleksji. Lubi ludzi.
</p>

<p>
    Każda z wymienionych osób ma wspólną pasję. Radio. Lubią słuchać audycji prowadzonych przez ten niezwykły głos, pełem ukojenia, spokoju, a zarazem zdecydowania.
</p>

<p>
    Marek lubi jak głos ciekawie opowiada i dzięki temu przyjemniej  prowadzi się autobus w tym tumulcie i znoju porannych narzekań.
</p>

<p>
    Sylwia słyszy w głosie nutę tajemniczości i jednocześnie subtelnej arogancji. Podoba jej się to.
</p>

<p>
    Karolina szuka w głosie ukojenia, które przychodzi zawsze. Po pierwszych minutach od rozpoczęcia audycji czuje wewnętrzny spokój. Upaja się tym.
</p>

      <h3 className="text-center mt-3 mb-3"><i>Pasja</i></h3>
      <p>
    Nie lubię tych chwil, gdy potrzebuję do łazienki, a tam akurat ktoś jest. Nie mam takiego pęcherza ze stali, co to pozwala
    mi wytrzymać parcie i jeszcze się do tego uśmiechać. Przecież nie wytrzymam tego! Przypomnę sobie teraz jeszcze jakąś wodę lub chociażby reklamę
    napoju i już. W takim stanie to wiele nie trzeba.
</p>

<p>
    Wyszła. Szybko, szybko, szybko, uff.  Co za ulga, uwielbiam to uczucie. Zdąrzyłem. Jest mały sukces. Wiedziałem, że zdąrzę tylko specjalnie się nakręcałem żeby niby to coś wielkiego.
    Robię tak tylko po to, żeby z niczego robić wielkie rzeczy, a potem spijać tę ambrozję sukcesu.
</p>

<p>
    Przed pracą potrzebuję jeszcze wziąć coś na rozluźnienie. Mimo, że efekt tych słów jest inny od oczekiwanego, to tak dokładnie muszę zrobić. Przełykam jedną tabletkę, której zadaniem
    jest spowodowanie, iż moje mięśnie pozwolą przez jakiś czas poczuć się komfortowo. Kiedy jestem już ubrany, sprawdzam, czy wszystko jest na miejscu. Wychodzę.
</p>

<p>
    Podróż zajmuje przeważnie około dwudziestu minut. Nauczyłem się już swobodnie wyjmować wózek z bagażnika. Zatem dystans z domu do mojego biurka w pracy to trochę ponad dwadzieścia minut.
    Celowo użyłem tego podkreślenie czasu, gdyż pokonywanie najmniejszych, czasem nawet głupich barier robi dobrze mojej psychice. Czuję wtedy, że mam wszystko pod
    kontolą i nie ma dla mnie żadnych przeszkód.
</p>

<p>
    Praca w radiu to od zawsze było moje marzenie. Lubiłem mówić, być słuchany. Tak naprawdę nigdy nie było wystarczającej publiczności przed którą występowałem.
    Nieważne w jakiej skali by ona była, to ja nigdy nie czułem tremy czy chociażby niewielkiego stresu. Kiedy przyszedłem do radia po raz pierwszy, to byłem pod takim wrażeniem, że
    już pokonanie niewielkiego dystansu z poczekalni do gabinetu prezesa było wędrówką trwającą wieki. Te wszystkie zdjęcia znanych spikerów. Napisy "Cisza" niemalże nad wszystkimi
    drzwiami. Czułem, że jestem w wyjątkowym dla mnie miejscu i chcę już tu zostać!
</p>

<p>
    Pierwsze audycje, to były właściwie ogłoszenia od naszych sponsorów.
</p>

<p>
    - Zakład wulkanizacyjny u Rafała - zaprasza na świąteczną wymianę opon! Przyjdź już dziś a wymienimy opony szybko i sprawnie!
</p>

<p>
    - Farbowanie i strzyżenie w jednej cenie! Fryzjerka Mariola zaprasza!
</p>

<p>
    To były początki pracy w radiu. Czas, w którym jeszcze mogłem tam pójść o własnych siłach. Od pewnego czasu używam wózka inwalidzkiego.
    Dość szybko zaadoptowałem się do nowej rzeczywistości. Jedyną zmianą, którą musiałem poczynić w pracy to prośba o nowe umiejscowienie mikrofonu.
</p>

<p>
    - W ten poniedziałkowy poranek, mimo tych łez nieba, pozwólcie drodzy słuchacze, że was przywitam!
</p>
    <h3 className="text-center mt-3 mb-3"><i>Żaneta</i></h3>
    <p>
    - Dziś już naprawdę nie mogę się spóźnić. - pomyślała Sylwia. Szybkim ruchem nałożyła buty i wyszła z domu.
</p>

<p>
    Tego dnia słońce świeciło jakoś nieprzeciętnie mocniej na obecną porę roku. Była połowa listopada. W sklepach zaczynało się robić ciasno od świątecznych ozdób.
    Sylwia już była w trakcie podróży do swojego biura. Na pierwszym cyklu świetlnym, tuż zaraz za skrzyżowaniem ulicy Kościuszki z ulicą Kołłątaja, udało jej się
    włączyć radio. Leciała właśnie ballada o nieszczęśliwej miłości. Sylwia doskonale znała tę piosenkę natomiast jak na złość w tym momencie
    nie mogła sobie przypomnieć nazwiska wykonawcy. Nucąc pod nosem smutną melodię wypatrywała czy na zegarze już jest ta godzina, o której miała zacząć się jej ulubiona audycja.
</p>

<p>
    Po ostatnim "you, you, you" odezwał się głos:
</p>

<p>
    - Tym przesadnie ckliwym, ale dla niektórych z was, bardzo romantycznym utworem, żegnamy wszystkie smutki dzisiejszego dnia i wchodzimy w tę radosną jego część. - powiedział głos.
</p>

<p>
    Sylwia lubiła jego poczucie humoru. Leciutki sarkazm, owiany nutą bezczelności z wyraźnym zaznaczeniem linii żartu. To jest to, co stawia ją na nogi i pozwala wierzyć, że
    mężczyzni z wysublimowanym poczuciem humoru chodzą po tej planecie i ona w końcu takiego znajdzie.
</p>

<p>
    - Dziś jak codziennie o tej porze zaczynamy cykl waszych telefonów. Dzwońcie do nas, dzielcie się z nami swoimi radościami, sukcesami. Im więcej takich historii tym
    ten dzień będzie lepszy. Zatem czekamy na pierwszego słuchacza.
</p>

<p>
    Po kilku sekundach.
</p>

<p>
    - Halo! Słychać mnie? - zaczął pierwszy słuchacz.
</p>

<p>
    - Tak, bardzo dobrze cię słychać. Z kim mam przyjemność? - odezwał się radiowiec.
</p>

<p>
    - Super! No to patrzcie na to, właśnie oświadczyłem się mojej dziewczynie. Zgodziła się od razu!
</p>

<p>
    - No to gratulacje! Cieszymy się razem z Tobą. Wspaniała informacja. A powiesz nam jak masz na imię?
</p>

<p>
    - Tak, padłem na kolana. Na początku nie mogłem z siebie słowa wydusić, ale potem już poszło! Zgodziła się! Ludzie! Zgodziła się!
</p>

<p>
    - Ok, gratulacje raz jeszcze. Dziękujemy ci za telefon. Prosimy dzwońcie do nas, ale przedstawiajcie się, chcemy poznać wasze imiona. - pożegnał słuchacza głos spikera.
</p>

<p>
    Kolejnym razem odezwała się inna osoba, która chciała podzielić się znalezieniem wspaniałej pracy. Telefony rozdzwoniły się jeden po drugim. Radiowiec witał gości, by za
    chwilę podziękować za rozmowę. W międzyczasie było kilka reklam i dwie piosenki. Po ich zakończeniu prowadzący odezwał się wznawiając telefoniczny cykl rozmów.
</p>

<p>
    - Halo? Czy słychać mnie? - powiedział jakiś damski głos.
</p>

<p>
    - Tak, cześć, jesteś na antenie. Z kim mamy przyjemność. - odpowiedział radiowiec.
</p>

<p>
    - Mam na imię Żaneta. Bardzo lubię was słuchać.
</p>

<p>
    -  Cieszymy się, że jesteś z nami Żaneta. Czym chciałabyś się z nami podzielić, co jest twoją radością?
</p>

<p>
    - Mój mąż wczoraj uderzył mnie tylko raz. Cieszę się, że zauważa swoje złe postępowanie i bije mnie rzadziej niż zwykle.
</p>

<p>
    W radiu zapanowała konsternacja. Dźwiękowiec i realizator popatrzyli na siebie. Nikt nie wiedział co zrobić. Czy może włączyć muzkę i przejść obok tego telefonu.
</p>

<p>
    Po dłuższej chwili milczenia przemówił prowadzący audycję:
</p>

<p>
    - To na pewno trudne przeżycie dla ciebie. Dobrze, że do nas zadzwoniłaś. Czy twój mąż jest teraz w domu, jest teraz z tobą?
</p>

<p>
    - Nie, jestem sama. Normalnie bałabym się zadzwonić czy w ogóle o tym komuś powiedzieć, ale napełniona od was falą pozytywnej energii zaczęłam się
    zastanawiać nad swoim życiem i zdałam sobie sprawę, że Marcin ostatnio bije mnie rzadziej. Uznałam, że to taki mój drobny sukces, którym chciałam się podzielić.
</p>

<p>
    - Rozumiem. A czy ktoś jest teraz przy tobie, jakaś rodzina albo bliska ci osoba?
</p>

<p>
    - Nie ma nikogo takiego
</p>

<p>
    - Mam do ciebie prośbę, nie rozłączaj się, ja włączę teraz muzykę, a za chwilę dokończymy rozmowę poza anteną, dobrze?
</p>

<p>
    Radiowiec dał znak ręką i w tle już leciała relaksująca muzyka. Szybko podjechał do swojego plecaka, wyciągnął telefon komórkowy i zaczął pisać sms:
</p>

<p>
    - Przyjedź do mnie do firmy ASAP.
</p>

<p>
    - Jestem cały czas z Tobą Żaneta. Powiedz proszę, czy macie dzieci? - zapytał prowadzący.
</p>

<p>
    - Tak, dwoje, Marysię i Zosię. Teraz są w przedszkolu.
</p>

<p>
    - A ty czym się zajmujesz, jeśli możesz powiedzieć?
</p>

<p>
    - Skończyłam akademię sztuk pięknych, trochę pracowałam przy renowacji zabytków, a pózniej poznałam Marcina, na świat przyszyły dziewczynki i dziś zajmuję się domem.
</p>

<p>
    - Czyli jesteś artystką, pięknie. Ja nigdy nie miałem żadnych zdolności artystycznych. Kiedyś poproszono mnie bym narysował samolot. To była jakaś zabawa z synkiem
    mojego przyjaciela. Gdy skończyłem rysunek usłyszałem takie pytanie: Dlaczego wujku narysowałeś latający krzyż?
</p>

<p>
    Nagle w szybie przedzielającej studio od reżyserki ukazała się twarz ślicznej kobiety. To była Kaśka. Znali się z radiowcem jeszcze z czasów szkoły. Zawsze była między nimi
    jakaś nić porozumienia, która pozwalała swobodnie rozmawiać na każdy temat. Kiedy Kaśka poszła na psychologię radiowiec wiedział, że to idealny wybór i na pewno zrobi w tej materii
    ogromną karierę.
</p>

<p>
    - Żaneta, chciałbym żebyś kogoś poznała, to moja dobra znajoma Kaśka, za chwilę przekażę jej telefon, ona bardzo chętnie cię wysłucha - mówiąc te słowa radiowiec dał znak Kaśce,
    by weszła do studia.
</p>

<h3 className="text-center mt-3 mb-3"><i>Czat</i></h3>
<p>
  Miniony dzień pozostawił we mnie uczcie smutku i żalu. Biedna ta kobieta, co wczoraj dzwoniła. Pół nocy o niej myślałem. Nie mogę uwierzyć, że mimo
  swoich problemów, mimo złego traktowania, taka żona wciąż stoi u boku swojego męża. Potrafi go nawet usprawiedliwiać albo zauważyć w nim iluzoryczny spadek
  agresji, który przypisuje jakiejś zmianie na lepsze. To jest trudny temat i nie do pojęcia w jedną nieprzespaną noc. Na szczęście mam dzisiaj wolne, rozerwę się trochę.
</p>

<p>
  Teraz szybki prysznic, ale najpierw muszę się trochę poruszać. Gdzie jest moja taśma? O, już ją widzę. Przykryłem ją czytnikiem.
  Rozkładam materac, a właściwie matę do ćwiczeń. Materac to dla mnie bardziej dmuchana rzecz używana do zabaw w morzu lub w innym basenie. Materac też kojarzy mi
  się z odleżynami, tzw. przeciwodleżynowy materac dla inwalidów. A ja przecież nie jestem inwalidą. Jeszcze kilkanaście lat temu
  uprawiałem sport. Tańczyłem tak dobrze, że nieraz się zdarzyło przykuć kobiece spojrzenie. Pupa to na same wspomnienia, aż mnie boli, tyle razy dziewczyny mnie w nią szczypały.
  Blask i podziw minął bezpowrotnie, a ja lubię wracać do tych wspomnień.
</p>

<p>
  Jestem już po prysznicu, ogoliłem się nawet. Teraz ta nietania woda kolońska, lubię ten zapach. Ubiorę dziś nowe dresy, te z metką.
  Odkąd moja fizjonomia zaczęła przypominać współczesnego Quasimodo to nie noszę już spodni typu jeans albo innych bardziej eleganckich.
  Wszystkie krzywizny i niedoskonałości pięknie gubią się w szerokich dresach. Dla mojej natury luźne dresy, nawet lekko opuszczone też są
  do pełnego zaakceptowania, gdyż lubuję się w muzyce hip hop od ponad dwudziestu lat więc całość w moim mniemaniu jest idealna.
</p>


<p>
  Włączyłem komputer. Jestem chorobliwie punktualny. Wolę trzydzieści minut wcześniej stać na deszczu lub śniegu niż przyjść dwie minuty po czasie.
  Tego samego wymagam od drugiej strony, co niesie za sobą ogrom nerwów i rozczarowań. Pracuję nad tym, żeby się zmienić, ale jak wszystko co robię i
  to wychodzi mi bardzo powoli.
</p>

<p>
  Jest. Przyszła. Czerwone słoneczko zmieniło się w żółte punkt dwunasta.
</p>

<p>
  - Cześć Maks! - Maks, a właściwie Maksymilian to moje imię z bierzmowania. Jest bardzo radiowe więc to jego używam.
</p>

<p>
  - Miło, że jesteś Paula. - odpisałem.
</p>

<p>
  Rozmowa trwała jak zwykle około dwóch godzin.
</p>

<p>
  Paula jest młodsza ode mnie i to dość sporo. Jeśli w ogóle można ocenić jaka różnica wieku
  jest akceptowalna między dwojgiem bliskich sobie ludzi.
</p>

<p>
  Był to jej drugi rok studiów dziennikarskich. Ja już pracowałem, jednak byłem dopiero w randze "przynieś, podaj".
  Specjalnie nie dodałem "pozamiataj", gdyż byłem na tyle wysoki, że do obsługiwania miotły musiałem sporo się zgarbić. To nie podobało się mojemu wydawcy, bo podobno zamykałem
  klatkę piersiową i głos w mikrofonie brzmiał jakoś źle.
</p>

<p>
  Nasze poznanie się wyniknęło z przypadku. Szedłem po kawę do baru na dół, a Paula akurat wchodziła po schodach niosąc przed sobą segregatory z archiwum. Szczęście do
  niefarta miałem od zawsze. Jak gołąb akurat siedział na dachu, a obok stało kilku moich kolegów, to prezent w postaci białego znaczka musiałem dostać właśnie ja. Było tak i
  tym razem. Widząc przed sobą wchodzącą postać zasłoniętą stosem dokumentów zrobłem unik i odsunąłem się na najbardziej zewnętrzną część schodów. Gdy nasze sylwetki były już obok siebie i
  wydawało mi się, że swobodnie mogę zrobić krok na przód, niefortunnie rozwiązane sznurowadło mojego buta zostało przycisnięte butem Pauli. Nie muszę dodawać, że o tym nie wiedziałem
  i intuicyjnie łapałem krok na następny schodek. To spowodowało, że straciłem rówowagę i przewrócłem się na Paulę.
</p>

<p>
  Po całym incydencie, który okrasiliśmy kilkoma minutami śmiechu, podaliśmy sobie dłonie patrząc sobie głeboko w oczy. Czułem wtedy, że to niezwykłe spojrzenie.
</p>
<h3 className="text-center mt-3 mb-3"><i>Paula</i></h3>
<p>
  Kiedy byłem w pełni sprawny nie cierpiałem na brak zainteresowania ze strony płci przeciwnej. Byłem wysoki, wysportowany i nie wstydziłem się dziewcząt.
  Z podniesionym czołem zaczynałem dialog, dorzucałem do tego jakiś niekiepski żart i to wystarczało, by cieszyć się pewnym uznaniem. Nie wspominam tu o wyjątkowym sposobie
  poruszania się podczas tańca czy czarującym uśmiechu, który tylko potęgował wspomniany efekt.
</p>

<p>
  Gdy powoli traciłem walory swojego zdrowia, kończąc skuloną pozycją na wózku, wiedziałem, że z kobietami, a właściwie nowymi relacjami będzie trudniej.
  W radiu pracowałem już przeszło dziesięć lat. Ostatni raz Paulę na żywo widziałem tuż po tym jak otrzymałem stanowisko w pełni niezależnego spikera. Ona była tuż przy końcu swoich studiów.
  Ostatni rok spędziła za granicą na wymianie studenckiej. Wybrała Pragę. To wprawdzie niedaleko od mojego miejsca zamieszkania, ale jakoś nigdy nie udało się fizycznie zobaczyć.
  Pozostał nam jedynie kontakt telefoniczny, a często tylko internetowy. Paula dzieliła pokój w akademiku z koleżanką, więc bez skrępowania łatwiej było rozmawiać poprzez czat.
</p>

<p>
  Dyskutowało nam się zawsze bardzo miło, cudownie, wyśmienicie. Paula rozumiała mnie w pół słowa. Gdy jeszcze nasza realcja miała charakter wizualny, czasem tylko jedno moje spojrzenie
  potrafiła odczytać w punkt. Czułem, że jesteśmy jak dwie bratnie dusze. Mimo, iż na początku, to fizyczność zadecydowała o obopólnym zainteresowaniu, to z czasem charakterologiczne
  scalenie stanowiło największą przyjemność tego związku.
</p>

<p>
  Na bieżąco informowałem Paulę, co u mnie się dzieje, jak wygląda moje życie. Ona też odwzajemniała przekazywaniem swoich czeskich doniesień.
  Ta wymiana informacji wraz z dość bogatą relacją fotograficzną pozwoliła mojej wyobraźni stworzyć jej obraz. Moim zdaniem niespecjalnie się zmieniła.
  Miałem poczucie, że właściwie jest taka sama jak przed wyjazdem.
</p>

<p>
  Jeszcze tylko kilka dni, aby pouzupełniać dokumenty odnośnie Erasmusa i miała wsiąść w pociąg powrotny.
</p>

<p>
  Nie mogłem się jej doczekać.
</p>
<h3 className="text-center mt-3 mb-3"><i>Pizza</i></h3>
<p>
    To był jeden z tych dni kiedy czuje się wszechobecną radość, pewność siebie. Wiedziałem, że to będzie wyjątkowy czas.
    Wszystko miałem zaplanowane od samego początku. Najpierw czekam na kuriera.
</p>

<p>
    W domu poruszam się bez wózka. Łażę tak jak pokraka. Zgięty niemalże w pół opierając się o ściany.
    Z kurierem Krzysztofem mam taką niepisaną umowę, że do domofonu ma dzwonić tak długo, aż otworzę.
    Nie zdążam w czasie kiedy mogę jeszcze otworzyć przyciskiem drzwi. Czas mechanizmu umożliwający czynność otwarcia drzwi jest dla mnie
    zbyt krótki.
</p>

<p>
    Kiedyś przy odbiorze paczki powiedziałem mu o tym i podziękowałem za powtarzalność w dzwonieniu.
    Popatrzył wtedy na mnie i miłym głosem odparł:
</p>

<p>
    - Spokojnie, ja wszystko wiem.
</p>

<p>
    Ogólnie uważam, że ludzie są z natury dobrzy. Małe dzieciątko wychodzące ze swojej mamy jest aniołkiem. To, co później w życiu człowieka spotyka, dodaje
    atrybutów obłudy, fałszu, ogólnie wszechpojętego zła.
</p>

<p>
    Po prysznicu zjadłem niewielkie śniadanie. Dziś z Paulą miałem zobaczyć się poraz pierwszy od bardzo dawna. To powodowało u mnie stan poddenerwowania i stresu,
    co przełożyło się na jeszcze większe spięcie moich mięśni.
</p>

<p>
    Prysnąłem się nowym zapachem, który dostarczył dziś kurier. Jeszcze telefon do pizzerii i właściwie mogę spokojnie czekać.
</p>

<p>
    Słońce z całą stanowczością wpadało do mieszkania swoimi promieniami. Piękna pogoda tylko podsycała we mnie zniecierpliwienie.
    W tle cichutko leciało "Wonderful life" zespołu Black.
</p>

<p>
    Mam duszę melancholijnego romantyka. Pierwszy refren wspomnianej piosenki sprawił, że
    wróciłem wspomnieniami do tych wspólnch chwil z Paulą, w których tarzaliśmy się ze śmiechu. Mieliśmy podobne poczucie humoru, bardzo zbliżony ironiczny żart.
    Przypominały mi się wspólne chwile, w których było między nam miło. Napawał mnie stan przyjemnego uniesienia.
</p>

<p>
    Tak bardzo pragnąłem, żeby już przyszła.
</p>

<p>
    Nagle rozległ się dzwonek do drzwi. Akurat byłem w przedpokoju. Całkiem sprawnie dotarłem do drzwi. To była ona.
    Nie rzuciliśmy się sobie w ramiona. Paula tylko zmierzyła mnie wzrokiem.
</p>

<p>
    - Miło cię widzieć. - powiedziałem jednocześnie otwierając szerzej drzwi. Jednak nie na tyle szeroko, by mogła swobodnie przejść obok mnie.
</p>

<p>
    Znała moje mieszkanie. Odwiesiła torebkę na wieszak. Znalazła swoje kapcie i z telefonem w ręku weszła do salonu.
    Ja w czasie wszystkich jej czynności zdążyłem jedynie zamknąć drzwi i przenieść ciężar całego ciała na mniej zmęczoną nogę.
</p>

<p>
    Usiadłem przy stole naprzeciw niej. Cały czas czułem jej spojrzenie, które gdzieś błądziło po moich dolnych kończynach.
    Opierając ręce o blat chwyciłem jej dłoń mówiąc:
</p>

<p>
    - Bardzo się cieszę, że już jesteś. Nie mogłem się ciebie doczekać, wiesz?
</p>

<p>
    Ona po raz pierwszy dłużej popatrzyła mi w oczy. Nie zauważyłem w nich radości. Był w nich rozlewający się smutek i żal.
</p>

<p>
    - Wiesz dlaczego przyjechałam? - powiedziała cicho i jakby niepewnie.
</p>

<p>
    - Jasne, że wiem! - odpowiedziałem pełen udawanej radości, ale i wewnętrznego niepokoju.
</p>

<p>
    - Musiałam się z tobą zobaczyć, żeby uczciwie porozmawiać. Nie chciałam tego załatwiać przez komputer czy telefon.
</p>

<p>
    Tej fali ciepła przeszywającej moje ciało nie zapomnę do końca życia. Moje nogi w sekundę stały się jak z waty. Dobrze, że wtedy siedziałem.
</p>

<p>
    W swoich rozmyślaniach dość często zastanawiałem się nad tym, jak naprawdę na moją postępującą chorobę zareaguje Paula.
    W rozmowach zdarzało mi się opisać nowo pojawiające się ułomności, ale ona zawsze te tematy ucinała mówiąc, że jutro będzie lepiej i żebym  sie nie martwił.
    Jakoś tak zwinne omijaliśmy te kwestię. Po jej ostatnich słowach zrozumiałem dlaczego. Ja się po prostu bałem. Na wskroś bałem się odrzucenia.
</p>

<p>
    - Co próbujesz mi powiedzieć? - resztkami odwagi wydusiłem z siebie.
</p>

<p>
    - W Pradze zobaczyłam swoją piekną przyszłość. To miasto urzeka mnie z każdej strony. Jest tam wielokulturowość. Ludzie są usmiechnięci,
    życzliwi. Będąc tam zrozumiałam, że chcę poznawać świat. Nie chcę się zamykać w mieszkaniu z kaleką. Jestem młoda, całe życie przede mną.
</p>

<p>
    - Ale Paula. - rzuciłem jedynie, tuż po tym jak ona zalała się łzami.
</p>

<p>
    Siedzieliśmy tak jeszcze kilknaście minut. Ona zapłakana z rozmytym tuszem pod oczami. Ja, jakby po ciosie kijem baseballowym w brzuch. Takim wielokrotnie
    zadanym ciosie. Najgorsze schorzenia tego świata to nic przy ciężarze jej słów.
</p>

<p>
    Potem jak przez mgłę patrzyłem na jej sylwetkę wkładającą buty. Drzwi za sobą zamknęła delikatnie, jakby czule. Zadbała o to. Nie było tego filmowego trzaskania.
    Zostałem sam. Tylko ja i piosenka:
</p>

<p>
    "No need to laugh or cry
    It's a wonderful, wonderful life"
</p>

<p>
    Kiedy zacząłem powoli do siebie dochodzić usłyszałem ponownie głos dzwonka do drzwi.
</p>

<p>
    - To ona. Wróciła. - tylko takie głosy miałem w głowie.
</p>

<p>
    Pospiesznie dopadłem do przedpokoju. Nie zwarzałem na bardzo sztywne nogi, które nie mogąc unieść tylko agresywnie przesuwałem po dywanie.
</p>

<p>
    W drzwiach stał uśmiechnięty dostawca pizzy. Miał na sobie zółtą koszulkę z logo pizzerii oraz podobnie wyglądającą czapkę z daszkiem.
</p>

<p>
    Pamiętam tylko jak cios pięścią spada na mój łuk brwiowy, by uszkodzić moje oko, które zdąrzyło do mózgu wysłać sygnał z informacją odebraną
    po przeczytaniu na plakietce imienia dostawcy:
</p>

<p>
    "Marcin"
</p>

      </div>
    )
  }
}

export default (Radiowiec)
