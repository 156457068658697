import React, {Component} from 'react'
import Navbar from './Navbar'


class Header extends Component {
  render(){
    return(
      <header className="mb-auto">
        <div>
          <h3 className="float-md-start mb-0"></h3>
            <Navbar/>
        </div>
      </header>
    )
  }
}

export default (Header)
