import React, {Component} from 'react'

class Panwladca extends Component {
  render(){
    return(
      <div>
      <h3 className="text-center mt-3 mb-3"><i>Ciężki żywot</i></h3>
      <p >
    Znowu to zrobił! Jak to on tak może? </p><p >Nie wie, że nie jest tutaj sam? Zapomniał? A może myśli, że jest w tym wszystkim taki subtelny i delikatny?
</p>

<p >
    Zaczynam mieć tego serdecznie dość!</p> <p >Przychodzi tutaj po ciemku,  myśli, że wszyscy jak tu śpimy, nie robimy nic innego tylko czekamy,
    aż łaskawie do nas zajrzy? No właśnie, że nie! Nie czekamy na nikogo! Po prostu sobie śpimy nie czekając na nic! Nie chcemy być budzeni,
    niespodziewanie nachodzeni. Czy on tego naprawdę nie rozumie? Niby taki wykształcony, oczytany, co to nie on, a tak prostej sprawy jakoś pojąć
    nie może! Nie wytrzymam tego dłużej! Ugryzę go! Rozszarpię na kawałki!
</p>

<p >
    Mdli mnie od tych poranków, kiedy wybudzają mnie te trzęsienia ziemi,
    te jęki biednego krzesła, które musi przyjąć tą ogromną masę cielska, którego nie podtrzymują już chyba żadne mięśnie. Skowyt i pisk wszystkich śrubek,
    które pewnie marzą, żeby już wypaść i zagubić się gdzieś wśród miliona zabawek porozrzucanych po podłodze. Nie wspomnę nawet o drzwiach,
    z których zrobił sobie podparcie i podtrzymując się  niezdarnie próbuje złapać pion. Biedna klamka, której biały kolor to już tylko wspomnienie,
    bo chcąc przyozdobić swoje palce obrączkami czy innymi różańcami zapomniał, że te ozdóbki mają ostre krawędzie i nie oszczędzają niczego,
    a już na pewno czegoś takiego jak farba.
</p>

<p >
    Usiadł. Sapania, wzdychania co niemiara! Teraz może wszyscy wstaniemy i zaczniemy mu klaskać? Jak ten mały, co jeszcze nie mówi tylko chodzi w kółko
    i klaszcze i namawia do tego innych. W  przypadku małego, to rozumiem, on jeszcze jest dzieckiem, raptem skończył dwa lata. Ale jego ojciec,
    a nasz Wybudziciel widać jest posiadaczem podobnego rozumu do swojego syna. O  zgrozo!
</p>

<p >
    Włączył komputer, monitor. Bezwarunkowa ciemność została przeszyta ujadającym blaskiem światła, nie ma w tym nic delikatnego tylko ostre,
    świetlne ujadanie! On coś w ogóle jeszcze widzi? Choroba nie zniszczyła mu wzroku doszczętnie? Przydałoby się, żeby kolejna część jego ciała
    odmówiła mu posłuszeństwa, to może by tego zaprzestał.
</p>

<p >
    Marzę o tym, żeby obudziło mnie jedynie chrapanie tej kobiety, co to niby jest jego teściową, choć jak czasem słyszę co o nim mówi,
    to dziwię się, że on tu jeszcze z nami mieszka...
</p>
      <h3 className="text-center mt-3 mb-3"><i>Rodzina</i></h3>
      <p >
    Odkąd zamieszkałem razem z nimi, a właściwie przyniesiono mnie tutaj, mogę powiedzieć o sobie, że jestem częścią tej patologicznej rodzinki.
    Inne słowa nie przychodzą mi na myśl, gdy popatrzę w jakich warunkach żyje tak liczna grupa osób na tak małej przestrzeni i co się tu wyrabia.
</p>

<p >W skład tej pożal się Boże familii wchodzą:
</p>

<p >
    On - Pan i władca wszystkiego, co jest w jego zasięgu, a że niewiele się rusza, to sam określił się panem tego terytorium. Cwaniak, wszystko inne
    co jest dalej niż ten pokój jest poza jego możliwościami kontroli.
</p>

<p >
    jego Żona - biedna kobieta, poświęciła mu się bezgranicznie decydując się na wyjście za mąż za tego łotra. Wyzbyła się różnego rodzaju przyjemności.
    Przecież On ciągle siedzi w domu, nigdzie jej nie zabiera, zaraz po pracy ona musi prosto wrócić do domu, bo daj Boże On gdzieś się przewróci
    i nikt poza nią nie będzie w stanie go podnieść. Smutny los tej wspaniałej kobiety.
</p>

<p >
    matka jego Żony - mieszka z nimi podobno od niedawna, ale dla mnie od zawsze tutaj jest. Nie pamiętam czasów bez niej. Starsza,
    schorowana kobieta, o gołębim sercu. Zawsze o mnie pamięta, podzieli się czasem kawałkiem marchewki lub innej dyni.On jak się zdenerwuje to
    dla niej jest równie nieprzyjemny jak z  resztą dla każdego z nas. Nie oszczędza jej... Kiedyś słyszałem jak naradzały się obie, że w
    korytarzu na noc zostawią kostkę masła, by do rana się rozpuściła. Wtedy gdyby On szedł na poranną toaletę... Nie było by czego,
    a właściwie kogo zbierać. Ale finalnie jego Żona się na to nie zgodziła. Chyba go kocha?
</p>

<p >
    ja - On mówi do mnie „Wiktor” - nie cierpię tego imienia, ja właściwie nie mam żadnego imienia. To On sobie tak wymyślił
    i wszyscy poddani mają to uznać! Lubię te chwile spędzane na wspólnych zabawach z Małym bądź z matką jego Żony. On wtedy śpi
    i takie życie jest dla nas wspaniałe. Widzę jak wtedy wszyscy się śmieją, żartują, a przede wszystkim oddychają z ulgą.
</p>
<p >
    Mały, jego syn - oczko w głowie nas wszystkich. Radosny, uśmiechnięty chłopczyk, który dopiero poznaje świat.
    Ciekawość małego dziecka powoduje uśmiech na twarzy dorosłego. To taki naturalny odruch człowieka. Nawet On potrafi się
    roześmiać, albo choć przez chwilę poświęcić mu swój czas.
</p>

<p >
    Najbardziej denerwuje mnie ten moment, gdy po raz kolejny On sadza synka na kolanach, by pobawić się z nim w „jeżdżenie” mapami Google'a
    po różnych miejscach. I po raz kolejny pokazuje dziecku to samo i zadaje te same, bezsensowne pytania:
</p>

<p >
    - Chodź synku sprawdzimy czy może dzisiaj naszą trasą jadą jakieś nowe samochody?
</p>

<p >
    Przecież wiadomo, że nie! Wczoraj, dziś i pewnie jeszcze przez długi czas „naszą” trasą będą jeździć te same samochody! Te same,
    bo Google nie wykona nagle nowych zdjęć, bo Pan i Władca tak sobie chce!
</p>

<p >
    Poza tym takie robienie z dziecka błazna, w moim odczuciu powinno niebawem skończyć się jakimś kolejnym rzutem, czy jak On na to mówi.
    Czymś co spowoduje jakąś szkodę w jego organizmie, czymś co będzie dla Niego odpowiednią karą za takie zachowanie...
</p>
      <h3 className="text-center mt-3 mb-3"><i>Bez różnicy</i></h3>
      <p >
    Dzień od dnia w tej rodzince właściwie różni się tylko datą na kalendarzu. On, czyli Pan i Władca jest błogosławieństwem
    każdego poranka. Szuranie i stękanie w mroku, który jest normalnym zjawiskiem dla tak wczesnej pory dnia, daje nam znać,
    że nowy dzień nastał! Wtedy jego żona, mimo błogiego snu, powoli musi zwlec się z łóżka, bo być może nasz Łaskawca będzie potrzebować pomocy,
    by wleźć do kabiny prysznicowej. Wleźć, bo tylko ludzie wchodzą lub wychodzą, a wszelkiego rodzaju gady bądź inne elementy włażą!
</p>

<p >
    Poranek tej biednej kobiety nabiera wówczas innego wymiaru. Mimo odgłosów, które wydobywa z siebie mały, próbując przywitać się ze swoją mamą,
    ona musi być w gotowości. Nasłuchuje czy, aby Wielmożny Pan gdzieś się nie zaklinował, bądź nie potrzebuje innej pomocy. A spróbowałaby
    nie usłyszeć choćby cichego wołania, choćby szeptu z błaganiem o pomoc. Nie chcę nawet myśleć o konsekwencjach, które mogłyby ją spotkać
    i pewnie wszystkich innych, którzy akurat napatoczyli by się na drodze.
</p>

<p >
    Następnie wszyscy jak tu jesteśmy oczekujemy, aż On zasiądzie na swoim tronie i zacznie wydawać codzienne dyspozycje:
</p>

<p >
    - Na śniadanie dziś nie będę jadł mięsa, mam ochotę na serek ze szczypiorkiem, jest w domu?
</p>

<p >
    W odpowiedzi cisza. Jeśli matka jego Żony oraz Żona milczą, to wiem, że po serek trzeba szybko pójść, bo go zwyczajnie nie ma.
    Ale żadna z nich nie ma odwagi wypowiedzieć tego na głos. Ciszę przerywa dość szybko On:
</p>

<p >- Nie ma serka? A co jest prócz wędlin i kiełbas? Kto ten dom prowadzi? Dlaczego jak zawsze mam na coś ochotę, to tego akurat nie ma?!
    Ja tu jestem najbardziej potrzebujący!
</p>

<p >
    Brak słów. Każda z pań tylko zagryza zęby i próbuje uspokoić swoje emocje. Jego Żona kupiła sobie kiedyś specjalną „protezę” na
    rozluźnienie wiecznie ściśniętej szczęki. Ale mały ją gdzieś znalazł i wrzucił mi do akwarium. Rozszarpałem ją dla zabawy.
</p>

<p >
    Jak jeszcze się nie domyśliłeś czytelniku, to jestem chomikiem. On tak bardzo chciał mieć chomika, bo gdy  był małym chłopcem,
    to miał już jednego. Tamten podobno wypadł z balkonu z piątego piętra. Sam wypadł? Nie wierzę w to! Nie wypadł tylko pewnie
    się rzucił. Wielmożny Pan już od dziecka musiał być nie do zniesienia. Ja odkąd jestem w tej rodzinie też juz kilka razy o
    tym myslałem, ale niestety mieszkamy na parterze...
</p>

<p >
    Po śniadaniu każdy idzie do swoich zajęć. On zostaje na krześle i niby pracuje. One idą sprzątać, myć naczynia, mały do swoich zabaw.
    I tak przez kolejne posiłki w ciągu dnia, narzekanie i sprzątanie, aż do wieczornego zawołania:
</p>

<p >
    - Przykryj mnie, ale nie zapominaj o nogach, nie lubię mieć zimnych stóp. Kiedy wreszcie będę mieć łóżko żeby mi nogi nie wisiały?
</p>

<p >
    Raz słyszałem, jak jego Żona w czasiego jego snu zakradła się do pokoju z takim dużym nożem i pod nosem mówiła:
</p>

<p >
    - Zaraz uwolnię cię od problemu wystających nóg!
</p>

<p >
    Ale z odwagą u niej gorzej..i na słowach się skończyło.
</p>

<p >
    Czy kiedyś coś wydarzy się inaczej i zaburzy tą monotonię? Bardzo jestem ciekaw...
</p>
      </div>
    )
  }
}

export default (Panwladca)
