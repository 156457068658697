import React, {Component} from 'react'
import {Link} from 'react-router-dom'

class Breadcrumb extends Component {
  render(){
    return(
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb mt-3">
          <li className="breadcrumb-item"><Link to={`/`} className="nav-link fw-bold">Strona główna</Link></li>
          <li className="breadcrumb-item active" aria-current="page">{this.props.label}</li>
        </ol>
      </nav>
    )
  }
}

export default (Breadcrumb)
