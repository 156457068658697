import React, {Component} from 'react'
import Breadcrumb from '../small-components/Breadcrumb'
import Footer from '../Footer'
import PostCard from '../PostCard'

function Texts() {
    return(
      <div className="container">
        <Breadcrumb label={'Teksty'}/>
        <div className="col-md-12 col-xs-12">
          <PostCard/>
        </div>
        <Footer/>
      </div>
    )
}

export default (Texts)
