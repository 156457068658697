import React, {Component} from 'react'
import {Link} from 'react-router-dom'

class Footer extends Component {
  render(){
    return(
      <footer className="mt-auto text-white-50">
        <ul className="nav justify-content-center pb-3 mb-3">
        </ul>
        <p>© 2022 Wszelkie prawa zastrzeżone.</p>
      </footer>
    )
  }
}

export default (Footer)
