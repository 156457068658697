import React, {Component} from 'react'

import SpaceBar from './../SpaceBar'
  class Dyspozytorka extends Component {
    render(){
      return(
        <div>
        <SpaceBar/>
        <p>
    Marcelina lubiła dni, w których jej służbowy telefon milczał. Mogła wtedy spokojnie podziwiać pejzaż miejski.
</p>

<p>
   - Jak można tak zmienić miasto? Przecież żyję tu od urodzenia i właściwie wszystko już było, miało swoje miejsce - pomyślała.

</p>

<p>
    Przyciskając nos do szyby podziwiała sąsiednie budynki, które były ściśle ulokowane pośród ulic.
     Obok sąsiedniego przemknęło właśnie kilka jaskółek.
    </p>

<p>
    Szklane szpilki. Tak zwykła je nazywać. Smukłe, nowoczesne drapacze chmur, które z okna jej gabinetu wyglądały jak igiełki powciskane w
    krawiecką gąbkę.
</p>

<p>
    Współczesna urbanistyka nie dawała oddechu. Liczył się tylko kolejny kawałek budowli.
</p>

<p>
    Marcelina lubiła swoją pracę. Pomaganie przestraszonym ludziom, odbieranie zgłoszeń oraz zapewnianie o zbliżającej się pomocy, to
    było to w czym czuła się najlepiej.
</p>

<p>
    Do tego rodzaju pracy miała odpowiednie predyspozycje. Rozumiała lęk i strach, który zdarzał się w  kryzysowych sytuacjach. Czymś takim było zacięcie się w windzie.
</p>

<p>
    Nagle w trakcie rozmyślań zadzwonił telefon. Szybkim krokiem podeszła do biurka i odebrała połączenie:
</p>

<p>
    - Telefon alarmowy konserwacji dźwigów Marcelina Nowakowska przy telefonie - jak zwykle stanowczo, ale i ze spokojem przywitała rozmówcę.
</p>


<p>
    - Halo! Słyszy mnie ktoś? Zaciąłem się w windzie! Pomocy! Proszę mnie stąd wydostać! - męski głos wrzeszczał do słuchawki.
</p>

<p>
    - Rozumiem, ale proszę podać adres. Gdzie pan teraz jest ?
</p>

<p>
    - Proszę pani ja się boję. Niech ktoś tu szybko przyjdzie i mnie wypuści. Ludzie pomocy!
</p>

<p>
    Marcelina wzięła głębszy oddech. Spojrzała na przejeżdżający autobus, który swoim trąbieniem wystraszył jaskółczą gromadkę
    i powtórzyła komunikat.
</p>

<p>
    - Proszę podać adres. Bez tego nie mogę wysłać serwisu.
</p>

<p>
    - Jestem w budynku przy Nowowiejskiej 8. Wyświetlacz pokazuje czwarte piętro, to pewnie na czwartym mnie zacięło - powiedział mężczyzna,
    zdrapując z ekranu gumę do żucia, która przysłaniała cyfrę 4.

</p>


<p>
    - Dziękuje, przyjęłam - odpowiedziała dyspozytorka.
</p>

<p>

</p>




        <SpaceBar/>
        <p>
    - Dobrze, że dziś  twoja mama wzięła do siebie Janka. W końcu będę mogła coś zrobić - powiedziała Wiola.
</p>

<p>
    - Masz rację kochanie - odparł Czarek nie odrywając wzroku od książki.
</p>

<p>
      Mężczyzna właśnie czytał. Nad obecną książką spędził już sporo czasu i zmierzał do końca.
</p>

<p> Był sobotni poranek. Jeden z tych brzydkich i nudnych dni.</p>

<p>
  Nagle spokój przerwało irytujące bzyczenie.
</p>

<p>
    Wielka czarna mucha latała wokół Czarka.

</p>

<p>
   Owad najpierw dreptał po jego czole, by po chwili zostawić swój ślad na jego lewym uchu.
</p>

<p>
    W tej rodzinie niemalże wszystkie męskie zajęcia były przypisane Wioli. Włącznie z tępieniem insektów i innych tego typu osobników.
    Mężczyzna świadomy swojego niewielkiego zaangażowania w powyższą materię postanowił samodzielnie poradzić sobie ze szkodnikiem. Jedną ręką domknął
    książkę, by po chwili oburącz zrobić zamach i uśmiercić muchę uderzeniem o blat stołu.
</p>

<p>
    - Jezus Maria! Czy ciebie do reszty pogrzało!? - wykrzyczała Wiola.
</p>

<p>
    Uśmiech na twarzy Czarka równie szybko zniknął, co się pojawił.
</p>

<p> - Maluję paznokcie, a ty durniu mnie straszysz!</p>

<p>
    - Wybacz kochanie, zupełnie nie zauważyłem, że jesteś zajęta - opowiedział Cezary.
</p>

<p>
    Truchło czarnego owada splamiło okładkę książki przysłanijąc jedną z tytułowych liter.
</p>

<p>
    - Oj Czaruś - drwiła żona.
</p>

<p>
    Mężczyzna jedynie spuścił głowę i starał się paznokciem zdrapać owada z okładki.
</p>

<p>
    - Daj mi znać Cezary, kiedy przestaniesz być ofermą - pod nosem powiedziała żona ścierając lakier z podłogi.
</p>

<p>
    On bardzo nie lubił tego imienia. Czarek brzmiało bardzo pospolicie i niektórzy jego znajomi złośliwie nazywali go owczarkiem, co wkurzało go
    do granic możliwości.
    A Cezary było przecież w tym podłym wierszyku:
</p>

<p>
    - "Cezary, Cezary,Cezary. Jakiś ty stary i bez fujary"
</p>

<p>
    Więc ze wszystkich możliwych wersji wolał tato - jak mawiał do niego
    jego synek, albo kochanie, jak zwykła mawiać jego żona. Oczywiście tylko wtedy, gdy miała dobry humor.
</p>




      <SpaceBar/>
        <p>
    Karolina stanęła przed windą i wybrała numer do swojego szefa.
</p>

<p>
    - Dzień dobry panie Czarku, jestem już w bloku u pańskiej mamy. Zaraz odbiorę Janka i przyprowadzę go do domu - przekazała wiadomość do słuchawki.
</p>

<p>
    - Bardzo się cieszę. Nie zapomnij zabrać ciasta - odpowiedział mężczyzna.
</p>

<p>
    - CHWDP! Śląsk Pany! - w oczekiwaniu na windę Karolina czytała napisy z pomazanych ścian.
</p>

<p>
    Dziewczyna pracowała jako opiekunka do dziecka. Bardzo dobrze znała adres babci swojego
    podopiecznego, a jeszcze lepiej napisy na klatce schodowej w jej bloku.
</p>

<p>
    Karolina była studentką. Zatrudniła się jako opiekunka do dziecka. Nie miała doświadczenia.
    Bała się, że sobie nie poradzi. Rodzina dla której pracowała okazała jej dużo sympatii i zrozumienia. A to przerodziło się w miłą współpracę dla obydwu stron.
</p>

<p>
  Gdy Czarek sprzątał pokój swojego dziecka, przygotowując się na przybycie malca, nagle zadzwonił telefon.
</p>
<p>
    - Panie Czarku chyba zatrzymaliśmy się w windzie - powiedziała spokojnym głosem Karolina.
</p>

<p>
    - Jak to się stało? - gwałtownie odpowiedział Czarek.
</p>

<p>
    - Chwile po naciśnięciu guzika po prostu przestała jechać .
</p>

<p>
    - Ok, zaraz wezwę serwis. Odszukaj proszę numer telefonu na pogotowie dźwigowe. Powinien znajdować się na jednej ze ścian.
</p>

<p>
    - Oczywiście. Chyba znalazłam. Proszę zapisać: 71-777-69-35 - podyktowała kobieta.
</p>

<p>
    - Już do nich dzwonię. Postaraj się Janka czymś zająć - odpowiedział  mężczyzna.
</p>

<p>
    - Halo, dzień dobry, z tej strony Cezary Wolny. Moja żona wraz z moim dwuletnim synkiem utknęli w windzie - zgłosił zdarzenie mężczyzna.
</p>

<p>
    - Proszę podać adres - odpowiedziała kobieta.
</p>

<p>
    - Ulica Kościuszki 65.
</p>

<p>
    - Dziękuję. Sprawdzam. O, ma pan szczęście, w niedalekiej odległości od tego miejsca znajduje się nasz serwis. Przyjmuję zgłoszenie.
</p>

<p>
  - Wspaniale! - z ulgą odpowiedział mężczyna.
</p>

<SpaceBar/>
        <p>
    - Dziś piwko? Pewnie! Będę - odparł chłopak.
</p>

<p>
    Pogoda od niemalże tygodnia była deszczowa. Prezenter w telewizji już właściwie nie szukał nowych określeń, by opisać warunki atmosferyczne.
</p>

<p>
    - Wrocław chyba nie zasłużył tego lata na żaden promień słońca. Tam wciąż pada, padało i będzie padać - powiedział spiker.
</p>

<p>
    Eryk złapał kurtkę do ręki i zapominając o wyłączeniu telewizora wybiegł z domu.
</p>

<p>
  Gdy schodził po schodach usłyszał odgłosy dobiegające z windy.
</p>

<p>
  - Pewnie znów się zacieła - pomyślał.
</p>

<p>
Wyszedł z budynku. Dotarł do przystanku i spojrzał na biegnącą kobietę, która chyba nie zwracała uwagi na otaczających ją ludzi. Patrzył jak wpada z impetem na staruszkę czekającą na autobus.
Seniorka jęknęła z bólu i przyklęknęła. Sprawczyni całego zajścia nie oglądając się  pobiegła dalej.
</p>

        <SpaceBar/>
        <p>
    Wiola pędziła co sił w nogach. Nie myślała o czerwonych światłach napotkanych po drodze. Liczył się tylko jej mały synek.
    Na samą myśl o przerażeniu, które musiał odczuwać chłopczyk, dostawała dodatkowej siły, która pozwalała jej biec szybciej.
</p>

<p>
    - Ile żon chciałoby mieszkać tak blisko swojej teściowej - wspominała wielokrotnie powtarzane słowa męża, gdy przywoływał z zachwytem niewielką odległość do
    swoich rodziców.
</p>
<p>
  Wiola nie lubiła ich wizyt. Częstych i niezapowiedzianych
</p>

<p>Teraz to, co zazwyczaj dla niej było udręką zdawało się być wybawieniem.</p>


      <SpaceBar/>

        <p>
    - Mamo wyjdź z mieszkanie na klatkę schodową - powiedział do słuchawki Czarek.
</p>

<p>
    Mężczyzna wiedział, że musi powiadomić o całym zajściu swoją mamę. Przecież to od niej przed chwilą wyszli i to w jej bloku
    utknęli w windzie.
</p>

<p>
    - Ale po co mam wychodzić. Czy coś się stalo? - odparła niczego nieświadoma kobieta.
</p>

<p>
    - Bo wiesz co, przed chwilą Karolina z Jankiem zacięli się w windzie.
</p>

<p>
    - O Boże! A gdzie są? Nic im nie jest?
</p>

<p>
    - Mamo spokojnie. Tylko zacięli się w windzie, już wezwałem pomoc - odparł rozbawiony mężczyzna.
</p>

<p>
    Kobieta odłożyła nagle słuchawkę.
</p>

<p>
    Natłok myśli gromadził się w głowie Czarka. Rozsądek przewyższał panikę. Mężczyzna przypomniał sobie te wszystkie razy, kiedy sam był uwięziony w windzie.
    Wielokrotnie sam ją zacinał,  robił to celowo. Ot taka głupia zabawa. Przez tego typu doświadczenia nie czuł strachu do tych blaszanych puszek. Bawiły go zawsze opowieści, które z
    przejęciem opowiadali ludzie, jak to straszna winda się zacięła i oni tak bardzo się bali. Czarek wiedział, że nic się nie stanie, trzeba jedynie cierpliwie poczekać
    na kogoś z serwisu.
</p>


    <SpaceBar/>
        <p>
    - Janek, Janeczku - krzyczała Wiola, uderzając ręką o drzwi windy na parterze budynku.
</p>

<p>Dobiegla do właściwego bloku i weszła do bramy razem z jakąś sąsiadką.</p>


<p>
    Rozpacz matki, która jest bezsilna i nie może pomóc swojemu dziecku jest nie do zniesienia.
</p>

<p>Na korytarzu robiło się powoli tłoczno od gapiów. Ludzie słysząc hałasy wychodzili ze swoich mieszkań, by sprawdzić co się dzieje.</p>


        <SpaceBar/>
        <p>
    - Proszę pani ja dzwoniłem przed kilkunastoma minutami. Mój  dwuletni synek płacze i się boi. - powiedział Czarek.
</p>

<p>Emocje zaczynały brać górę i mężczyzna ponownie wykonał połączenie do pogotowia dźwigowego.</p>

<p>
    - Rozumiem, ale ja nic więcej nie mogę zrobić. Pomoc   już jedzie - odpowiedziała dyspozytorka.
</p>

<p>
  Nastała cisza. Mężczyzna zamilkł. Po krótkim czasie zadał pytanie:
</p>

<p>
    - A może mi pani chociaż powiedzieć jaki kolor ma samochód którym przyjedzie serwisant?
</p>

<p>
  Dyspozytorka podniosła brwi ze zdziwienia. Nie wiedziała co odpowiedzieć.
</p>

<p> - Kolor auta? A po co panu kolor auta? - wydukała wreszcie.</p>

<p>
  - No wie pani, żona tam biega wokół budynku i czeka aż przyjedzie serwis. Gdyby znała kolor auta to, by wiedziała jakiego samochodu wypatrywać - argumentował mężczyna.
</p>

<p>
  - Jak to żona biega wokół budynku? Przecież powiedział pan, że jest z synem w windzie - zapytała kobieta nie kryjąc w głosie zdziwienia.
</p>

<p>
  - No tak, yyy to do widzenia - mężczyna pospiesznie zakończył połączenie.
</p>

<p>
  Marcelina przywykła do irracjonalnych ludzkich zachowań w stresie. Uśmiechnęła się lekko pod nosem i wróciła do swoich obowiązków.
</p>

        <SpaceBar/>

        <p>
          Czarek, gdy się denerwował lubił oglądać boks. Kiedy poczuł, że sytuacja wymyka się spod kontroli prędko znalazł w internecie relację jakiejś minionej walki. Chciał się trochę uspokoić.
        </p>

        <p>Szybkie stuknięcie w klawiaturę i znalazł. Teraz patrzył jak czarnoskóry umięśniony facet okłada jakiegoś wychudzonego azjatę.</p>

        <p>
          - Kto pozwolił na walkę tych dwóch - pomyślał.
        </p>

        <p>
          - Przecież od razu widać, że czekoladka jest lepszy - zaśmiał się w duchu i poczuł falę ulgi rozchodzącą się po całym ciele. Stres znikał.
        </p>

        <p>
        Patrzenie jak ktoś inny ma ciężko, gorzej, umniejszanie ludziom to sprytny zabieg psychologiczny, by zdjąć z siebie pewien ciężar, z którym sobie nie radzimy.
        </p>

        <p>
          To zawsze mu pomagało.
        </p>

        <p>
          Chwile relaksu przerwał dzwoniący telefon. Na wyświetlaczu napisane było  "Tato".
        </p>

        <p>
          - Nie teraz, proszę cię - pomyślał.
        </p>

        <p>
          Cezary nie pozwolił ojcu długo czekać i szybko odebrał połączenie.
        </p>

        <p>
          - Daj mi tam numer, ja zadzwonię. Ile oni będą jechać - rozpoczął rozmowę ojciec.
        </p>

        <p>
          Wystraszona mama Czarka w międzyczasie zatelefonowała do swojego męża. Opisła całą sytuację.
          Nie stroniła od przesady dodając nieco dramaturgii właściwemu zajściu.
        </p>

        <p>
          - Ja już właściwie dzwoniłem, wszystko mam pod kontrolą - nieśmiało padła odpowiedź.
        </p>

        <p>
          - Co masz pod kontrolą? Mój wnuk siedzi w windzie gdzieś pomiędzy piętrami, a matka lata po schodach z płaczem - odparł rozwścieczonym głosem mężczyzna.
        </p>

        <p> - No dobra pisz - powiedział z rezygnacją Czarek.</p>

        <SpaceBar/>

        <p>
          W mieszkaniu przy ulicy Konduktorskiej słychać było jedynie krzyki.
          Starszy mężczyna z słuchawką od telefonu stacjonarnego w ręku, co chwila wstawał impulsywnie i siadał.
          Widać było, że przeżywa rozmowę. Drobinki śliny padały na blat stołu kuchennego, na którym stał talerz z kanapkami.
          W tle deszcz uderzał o blaszany parapet, a szare niebo wydawało się być już zmęczone natłokiem chmur.
        </p>

        <p>
          - Co mi pani będzie mówić, że jedzie. Kochaniutka, ja tak tego nie zostawię. Tłumaczę pani, że mój wnuk siedzi sam w windzie i płacze - natrętnie przekonywał mężczyzna.
        </p>

        <p>
         - Proszę pana ja już rozmawiałam z kimś w tej sprawie. Wysłaliśmy serwis. Jest niedaleko. Poza tym dwuletnie dziecko nie może jeździć windą bez osób dorosłych więc jak to możliwe, że ono jest tam samo? - spytała kobieta.
        </p>

        <p>
          - No wie pani jakie są dzieci. Wymsknął mi się i sam wsiadł do windy.
        </p>

        <p>
          - To jak to w końcu jest? Kto jest zamknięty w tej windzie? Dzwonicie cały czas z inną historią. Chyba wycofam to zgłoszenie -  skontrowała Marcelina.
        </p>

        <p>
          Dziadek chłopca nerwowo zaczął się tłumaczyć. Przeprosił za zamieszanie. Przyznał, iż trochę przeinaczył sytuację myśląc, że to przyspieszy ratunkowe działania.
        </p>
          <SpaceBar/>
        <p>
          Karolina spoglądała na ekran swojego telefonu. Oglądali bajkę wspólnie z Jankiem. Chłopiec spokojnie znosił całą sytuację.
          Był dzielny. Gdy tylko słyszał jakieś odgłosy z zewnątrz, to błyskawicznie tłumił je sztuczny śmiech dziewczyny. Studentka potrafiła znakomicie odnaleźć się w trudnych momentach.

        </p>

        <p>
          Nagle w drzwiach windy słychać było dziwny dźwięk. Jakby ktoś wkładał klucz. Drzwi się otworzyły. Przed  nimi stał młody mężczyzna w koszulce z napisem "Dźwigowe pogotowie".
        </p>

        <p>
          Wiola na widok synka rozpłakała się. Chłopiec odpowiedział tym samym. Zrozpaczona babcia wstała ze schodów i podbiegła do wnuka.
          Po kilkunastu minutach wszysycy wyszli na zewnątrz.
        </p>

        <p>
          Eryk właśnie wracał do domu i spostrzegł zapłakane kobiety. Jedna z nich szczególnie przykuła jego uwagę. Była zniewalająco piękna.
        </p>

        <p>
          Jakiś mały chłopiec mocno trzymał ją za rękę.
        </p>
        <p> - Pewnie to jej dziecko - pomyślał mężczyzna.</p>

        <p>Kobieta pożegnała się z pozostałymi osobami i ruszyła w innym kierunku.</p>

        <p>Eryk wyciągnął klucze z kieszeni i zaczął owierać zamek uśmiechając się do siebie.</p>

        <p> - Te wszystkie piękne są już dawno zajęte - pomyślał.</p>
        </div>
      )
    }
  }

  export default (Dyspozytorka)
