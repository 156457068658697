import React, {Component} from 'react'

import Header from './Header'
import Main from './Main'
import Footer from './Footer'

function Home() {
    return(
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
        <Header/>
        <Main/>
        <Footer/>
      </div>
    )
}

export default (Home)
