import React, {Component} from 'react'

class Stefania extends Component {
  render(){
    return(
      <div>
      <h3 className="text-center mt-3 mb-3"><i>W kolejce</i></h3>
      <p>
          Każdy poniedziałek w tej przychodni, jak i zapewne w każdej innej, zaczynał się tak samo. Już pod samym budynkiem sznur ludzi czekających na jej
          otwarcie ciągnął się wzdłuż ulicy. Nieszczęśliwie Słonecznikowa była akurat w remoncie. W związku z tym w połowie kolejki stało dwóch mężczyzn,
          którzy wpuszczali jedną część grupy na chodnik, by druga nie plątała się gdzieś po przejściu dla pieszych. A to stanowiło nielada
          niebezpieczeństwo dla samych oczekujących jak i dla kierowców. Wszyscy, którzy byli w kolejce w tej części „chodnikowej” wyglądali prawie jak
          zwycięzcy jakiegoś biegu lub co najmniej maratonu. Uśmiech na twarzy, lekki błysk w oku, wszystko okraszone niewielką zadyszką. Można było odnieść
          wrażenie, że dla tych ludzi znajdowanie się na chodniku tuż przed przychodnią  jest swego rodzaju nobilitacją i skoro udało im się dotrzeć aż do tego
          miejsca, wygrać drobną przepychankę z innymi z kolejki, to właściwie dzień jest udany i można by spokojnie już wracać do domu.
      </p>

      <p>
          Kiedy na zegarze wybiła godzina siódma drzwi przychodni nagle się otworzyły i rozpoczął się kolejny etap rywalizacji dla tych, którzy postanowili jednak
          powalczyć dziś o większe laury,  o jak najlepsze miejsce na ławeczce przed gabinetem lekarskim. Mimo, iż średnia wieku kolejkowiczów dawno
          przekroczyła wiek emerytalny dla mężczyzn w Polsce, to werwy i pomysłowości nie można się było powstydzić. Jeden mężczyzna niechcący podstawił
          nogę jakiejś kobiecie, by ta poddając się prawu grawitacji mogła swobodnie sprawdzić temperaturę chodnika swoim policzkiem. Jakaś mama z dzieckiem
          wchodząc barkiem w tęższego od siebie mężczyznę zupełnie zapomniała o ręce swojego dziecka, którą tak mocno ścisnęła, że dziecko wydało się z siebie
          przeraźliwy krzyk. Co okazało się bardzo pomocne, bo ogół pozostałych na chwilę jakby zastygnął, by sprawdzić skąd dochodzi ten odgłos. Po przepychankach
          tłum ludzi wtargnął do przychodni.
      </p>

      <p>Gdy już wszyscy zasiedli na swoich miejscach przed gabinetem pojawiła się ona. Weszła powoli,
          nieznacznie drobiąc krok. Wyprostowana, elegancko ubrana. Ciepły uśmiech na jej twarzy nadał jakby temperaturę temu pomieszczeniu.
          Wszyscy skupili się tylko na niej. Mimo szyku oraz zadziwiająco zdrowej sylwetce można było założyć, że ów przedziwna pani jest już sędziwego wieku,
          co tylko wzbudzało większy podziw oraz zazdrość wśród zebranych.
      </p>

      <p>Kolejka skracała się dość wolno. Jeden mężczyzna przebywał w gabinecie
          lekarskim około pół godziny, co nie do końca wszystkim się podobało. Ktoś  z kolejki dał temu wyraz w bardzo niegrzeczny sposób i na odchodne
          temu mężczyznie krzyknął:
      </p>

      <p>
          - Panie, tak długo posiedzieć to pan sobie może u babci na obiadku a nie w gabinecie lekarskim!
      </p>

      <p>
          Atmosfera była coraz bardziej gęsta. Każdemu się spieszyło, każdy chciał już wejść do lekarza. Nikt spośród zebranych nawet nie zauważył,
          iż elegancka pani ani przez chwilę nie usiadła. Po pewnym czasie zreflektował się jeden starszy pan i próbując być grzeczny wstał ze swojego
          miejsca i wskazał je zachęcając, by elegancka pani z niego skorzystała. Ona tylko uśmiechnęła się  życzliwie, mówiąc:
      </p>

      <p>
          - Bardzo dziękuję za
          uprzejmość, ale nie potrzebuję, postoję.
      </p>

      <h3 className="text-center mt-3 mb-3"><i>Wakacje</i></h3>
      <p>
        Piłka odbiła się od ławki, następnie od ściany, by już z mniejszym impetem uderzyć w budę, w której smacznie spał, a własciwie leżał zmęczony czerwcowym
        słońcem Azor. Pogoda nie dawała wytchnienia. Zapowiadało się upalne lato. Włodek już snuł plany na tegoroczne wakacje. Planowali je już od dawna z tatą.
        Jeszcze tylko jutro, zakończenie roku i wszyscy całą rodziną wyjadą do Sopotu. Wujek Piotr z ciocią Grażyną już zapewne wszystko przyszykowali.
        Z całej rodziny to Stefa była najbardziej zniecierpliwiona. Ona tak lubiła morze, piasek na plaży. Jeszcze w ostani piątek byli na targu w Lublinie
        i kupili ten wymarzony latawiec.
      </p>

      <p>Rodzeństwo, a chyba bardziej bliźniaki, jak czule mówiła o nich mama, nie odstępywali siebie na krok.
        Ledwie rok różnicy był między nimi, a w oglóle nie można było tego zauważyć. Chowali się tak jakby przyszli na świat w tym samym czasie.
        Oboje w tej samej chwili mieli ochotę na jabłko albo inny owoc, który mama właśnie przyniosła z ogrodu. Oboje w tym samym momencie kopali piłkę.
        Ta sama chwila przynosiła ochotę na tę samą psotę bądź na tę samą zachciankę. Włodek był nieznacznie starszy, ale to Stefa jakby była bardziej
        opiekuńcza i troskliwa o swojego brata.
      </p>

      <p>- Włodek! Stefa! Prosiłam tyle razy żebyście nie grali w piłkę tuż przed domem. Macie na to całe podwórko.
        Tato niedawno pomalował płot i teraz wszędzie są na nim ślady piłki. – zmęczonym głosem żaliła się mama.
      </p>

      <p>
        - Tak, tak. Pamiętamy, tylko to nowa piłka kupiona na plażę i musieliśmy ją sprawdzić mamo. Jak tylko wrócimy z Sopotu to jeszcze raz wszystko
        pomalujemy – z urzekającym uśmiechem odparła Stefa.
      </p>

      <p>Rodzeństwo nie sprawiało kłopotów. Na każde srogie spojrzenie taty od razu poprawiali się
        w zachowaniu, a każda prośba matki była przez nich od razu wykonywana.
      </p>

      <p>
        Mama właśnie podała obiad. Włodek zawołał Stefkę, by już przestała
        bawić się z Azorem tylko szybko przyszła do domu, bo mama nie będzie dwa razy podgrzewać zupy. Po kilku minutach wszyscy zasiedli do stołu i
        czekali jak lada chwila w drzwiach pojawi się tato. </p>
      <p>Z Lublina do Kalinówki było niecałe dziesięć kilometrów. Tato zawsze w czwartki miał
        tylko jedną lekcję i w domu bywał punktualnie o dwunastej.  Kilka minut po ostatnim uderzeniu zegara na podwórko wjechał samochód. Po chwili
        stukanie w drzwi i ku zdziwnieniu wszystkich  w jadalni pojawiło się dwóch mężczyzn. Jeden ewidentnie był wojskowym. Włodek już widział podobne
        umundurowanie w książkach od hisorii. Długi płaszcz, jakieś dzwine naszywki na ramionach. To chyba pagony - pomyślał. No i ten długi i brudny
        karabin. Wszyscy byli przestraszeni, nie wiedzieli co się dzieje. Gdze jest tato? – pomyślała Stefka.</p>

      <p>
        - Róża Nowacka? – zapytał ten drugi,
        chyba cywil, jakoś tak dziwnie uśmiechnięty.</p><p>- Tak, kim panowie są? Co się tutaj dzieje? – drżącym głosem odparła mama.
      </p>

      <p>
        - Ma pani trzydzieści minut żeby się spakować. Spokojnie, ja pomogę -   odparł cywil z tym samym niegasnącym uśmiechem.
      </p>

      <h3 className="text-center mt-3 mb-3"><i>W pociągu</i></h3>
      <p>
        Na dworzec pojechali przez główną drogę w Kalinówce. Wiele rodzin zostało przetransportowanych w podobny sposób. Widać było
        porozrzucane po drodze zabawki, które dzieci gubiły w pośpiechu lub ze strachu. Jeden z samochodów rozjechał jakieś
        poduszki czy coś podobnego z pierza, gdyż nagle na drodze zrobiło się biało od piór. Widać było, że atmosfera we wsi jest bardzo nerwowa i ludzie w popłochu próbowali
        zabrać co najpotrzebniejsze. Nie zawsze żołnierze, którzy zabierali ludzi do ewakuacji byli pomocni lub mieli w sobie choć niewiele z ludzkiej empatii.
        Trudno wymagać od najeźdców, by oszczędzali swoje ofiary, jednak tego rodzaju postępowanie wykraczało poza ludzkie pojmowanie zła.
        Jeden z żołnierzy nie mógł już dłużej czekać, aż pewna matka spakuje swoje dzieci, więc porozrzucał jej wszystkie pakunki na drodze, a następnie je podpalił.
        Najgorzej było jak dzieci były rodzielane od rodziców. Wojskowym bardzo zależało na czasie i nie zważali na przerażenie ludzi i jak ktoś bardzo się
        ociągał to zabierano mu dziecko i wrzucano na pakę do  przejeżdżającego obok samochodu. Rozpacz matki, która na to bezradnie patrzyła przeszywała serce na wskroś.
    </p>

    <p>
        W samochodzie, w którym siedziała rodzina Nowackich znajdowało się jeszcze dwoje innych ludzi. Na niewielkiej przestrzeni wszyscy byli
        przewożeni wraz z ledwie przechwyconym dobytkiem. Stefania  zdążyła zabrać jedynie nowy latawiec, ten sam, co to mieli go puszczać wraz z Włodkiem
        na wakacjach w Sopocie. Między rodziną panowała cisza. Każdy zastanawiał się gdzie się podział tato, a właściwie co z nim zrobili. To, że przydarzyło
        mu się coś przykrego wręcz okropnego było pewne.
    </p>

    <p>
        Gdy usłyszeli gwizd odjeżdżającej lokomotywy zdali sobie sprawę, że zbliżają się na stację kolejową. Przed nasypem stało już
        wiele samochodów, z których wysiadali inni przewożeni. Płaczu, krzyku i jęku było tak wiele, że Stefka mocno chwyciła się swojej mamy mówiąc </p>

    <p>
        - Mamo, nie idźmy tam, proszę.
    </p>

    <p>
        Dla dzieci ta sytuacja była przerażająca. Dlaczego każdy oprawca bądź najeźdca nie zastanawia się nad losem bezbronnych dzieci, które mimowolnie
        stają się ofiarami. Przecież to nie one są prowodyrami jakichkolwiek konfilktów.
    </p>

    <p>
        Wepchnięto ich do wagonu pełnego innych ludzi. W środku unosił się zapach zwierząt, jakby niedawno to one były tutaj przewożone. Dorośli mimo, iż próbowali
        zwracać uwagę na swoje pociechy, to i tak każdy niezależnie od wieku był traktowany tak samo. Każdy był popychany, kopany. Każdy próbował wywalczyć
        choć kawałek przestrzeni dla siebie. Włodek, jako jedyny męski przedstawiciel swojej rodziny, od razu próbowal osłaniać swoich bliskich. Niestraszne mu były
        różnorakie wyzwiska bądż akty agresji kierowane w jego stronę.
        W takich warunkach spędzili dłuższy czas. W wagonie było ciemno i nikt nie potrafił powiedzieć jaka jest pora dnia. Jedynie szczeliny między deskami w ścianach
        wagonu mogły podpowiadać czy jest dzień czy noc. Zwierzęcy fetor zmieszany z potem i zapachem ludzkich nieczystości dodawał obrzydliwości temu miejscu.
    </p>

    <p>
        W pewnym momencie pociąg nagle się zatrzymał. Mocne szarpnięcie wagonu spowdowało upadek wszystkich, którzy stali.
        Stefania upadła na podłogę uderzając swoją głową o kierownicę czyjegoś roweru. Włodek został tak mocno popchnięty w plecy, że
        nie był w stanie ustać na nogach i przy upadku zdarł sobie skórę z dłoni. Dla wszystkich to nieprzewidziane zatrzymanie pociągu było przykre w skutkach.
    </p>

    <p>
        Nagle otworzyły się drzwi. Przed zebranymi ukazała się przerażająca, śliniąca się psia morda. Obok stało dwóch żołnierzy, którzy
        mierzyli w stronę przyjezdnych ze swoich karabinów.
    </p>

    <p>
        - Wysiadać! - krzyknął jeden z nich.
    </p>

    <h3 className="text-center mt-3 mb-3"><i>Sąsiedzi</i></h3>
    <p>
    Z jednej strony trzymałam mamę za rękę, a z drugiej szedł Włodek. Szliśmy tak za grupą ludzi z tego samego pociągu.
    Mama miała zapłakaną i opuchnięta twarz. Za każdym razem kiedy próbowała ukryć łzy odwracała głowę w
    drugą stronę. Nie chciała żebym to zauważyła, ja też nie dawałam tego po sobie poznać.
</p>

<p>
    Podczas wysiadania z pociągu Włodek doskoczył do jednego z żołnierzy i próbował wyrwać mu broń. W trakcie walki obaj upadli na
    ziemię, lecz po chwili podbiegł do nich inny i tak kopnął Włodka w brzuch, że ten krzyknął z bólu. Następnie poprawił ciosem w twarz,
    która momentalnie pokryła się krwią. Mama od razu wpadła w panikę, zaczęła płakać i prosić ich, by go zostawili, by przestali go bić. Kiedy
    zrobiło się głośno od krzyków wśród innych ludzi, to zauważyłam jak w naszym kierunku zmierzał na koniu jakiś inny żołnierz. Chyba jakiś oficer, bo
    pozostali żołnierze szybko się uformowali w szereg i zostawili Włodka w spokoju. Dowódca, gdy był już bardzo blisko krzyknął do nich:
</p>

<p>
    - Co robicie?! To nie czas ani miejsce na bijatyki! Musimy odprawić ich do obozu.
</p>

<p>
    Ta interwencja przerwała krzywdy mojego brata, ale mnie bardzo przestraszyła. To co powiedział tamten oficer było niepokojące.
</p>

<p>
    - Przygotowali dla nas obóz? Co za obóz? - pomyślałam. Podobne zaniepokojenie, a wręcz przerażenie, widziałam  w oczach mamy.
</p>

<p>
    Od stacji kolejowej do miejsca, w którym planowano nas zatrzymać było niedaleko. Pamiętam jak wchodziliśmy na niewielkie wzniesienie,
    by po chwili z niego zejść wśród śpiewu ptaków i szumu szyszek, których wokół nas było wiele. Wszędzie rozpościerał się piękny, iglasty las. Było tam bardzo cicho, spokojnie. Pamiętam to uczucie
    jak wiatr smagał mi delikatnie twarz dodając uroku temu miejscu. Gdyby nie wcześniejsze okoliczności i wciąż pozostające w mojej głowie pytanie,
    gdzie jest tato, to pewnie nie chciałabym stąd już wracać.
</p>

<p>
    W środku malowniczej scenerii stało kika domów o podobnej budowie. Czerwone, ceglane budynki z oknami, przykryte czarnym, smolistym dachem.
    Postawiono ich tam około czterech bądź pięciu. Każdy z nich miał duży komin, niewielki ogródek przy wejściu. Wszystko na pierwszy rzut oka
    wydawało się być normalną osadą kilku domów, o które ktoś kiedyś odpowiednio zadbał.
</p>

<p> Czar prysł, gdy podchodząc bliżej budynków usłyszałam piski i krzyki
    ludzi, którzy wołali, że już nie ma miejsca, że pozostali się nie zmieszczą. Okazało się, że nas rówież przyprowadzono do niewielkiego
    pomieszczenia, gdzie już przebywała inna rodzina. Wszyscy siedzieli na podłodze i na widok żołnierzy zaczęli płakać.
</p>

<p>
    Pozostawiono nas w budynku, który był budowany dla jednej rodziny, a liczba osób znajdujących się wewnątrz sięgała dwudziestu.
</p>

<h3 className="text-center mt-3 mb-3"><i>Praca</i></h3>
<p>
    Zaprowadzono nas do wielkiej jaskini. Ogromne bloki skalne, które stabilnie stały przed jej wejściem, napawały mnie strachem, ale
    i zaciekawieniem. Dochodzący z wnętrza stukot krył za sobą pewną tajemnicę, którą prędko chciałem odkryć. Gdy byłem coraz bliżej
    tajemniczej groty tym  bardziej odgłos wydawał się być wyraźniejszy. Łomotanie mieszało się z ludzkimi okrzykami. Wchodząc do środka
    zobaczyłem, że jaskinia, to tak naprawdę kamieniołom. Było tam wielu mężczyzn, kórzy ciosali kamienie wielkimi,
    ciężkimi młotami. Tych, którzy nie mieli już sił zabierano szybko na zewnątrz, gdzie przejmował ich oddział żołnierzy w dużych
    dostawczych samochodach. Wyczerpanych, wycieńczonych ludzi wyworzono podobno na stracenie.
</p>

<p>
    Mnie pokierowano do prac przy wynoszeniu mniejszych kawałków kamieni. Podobne rzeczy wykonywali inni chłopcy w zbliżonym do
    mnie wieku. Pierwsze rany na dłoniach, od ostrych jak brzytwa krawędzi skał, pojawiły się u mnie pod wieczór. Pracowaliśmy od rana do zmroku.
    W czasie dnia pojono nas wodą oraz karmiono suchymi jak wydobywane skały kromkami chleba. Pozostali mężczyzni byli oddelegowani do znacznie
    cięższych prac, wnosili do środka jaskini ogromne młoty, kilofy i inne stalowe narzędzia.
</p>

<p>
    Liczna grupa mężczyzn została podzielona na dwie mniejsze. W jednej z nich znajdowali się tacy, którzy mogli do wydobycia kamienia
    używać pomocy w postaci metalowych przyrządów. W pozostałej przebywali wszyscy, którzy sprzeciwali się żołnierzom i dawali temu wyraz
    swoim nieposłuszeństwem.
    W tej grupie nie wolno było używać żadnych pomocy. Wydobycie odbywało się jedynie za pomocą własnych dłoni. Na tę część roboników żołnierze
    mówili <i>strupy</i>, bo po pewnym czasie od prac gołymi rękami robiły im się rany, które szybko zasychały od wszchobecnego pyłu, pozostawiając po sobie rozległe strupy.
</p>

<p>
    Nagle moje serce przyspieszyło swoje bicie. Zrobiło mi się duszno, ciśnienie w skroniach tak mocno skoczyło, że
    czułem jakby cała głowa mocno pulsowała. Nie mogłem uwierzyć w to co widzę. Wśród grupy strupów, na wpół zgięty, stał
    mój ojciec.
</p>
<h3 className="text-center mt-3 mb-3"><i>Stój prosto</i></h3>
<p>
    Od kilku tygodni przebywałam w nowym domu sama z mamą. Pamiętam ten dzień jak przyszli po mojego brata.
    Nie pytali o jego wiek, po prostu wyciągnęli go o świcie z domu. Spałam tak twardo, że całą przykrą historię
    zrelacjonowała mi mama. Ewidentnie szczędziła mi szczegółów. Powiedziała tylko, że Włodek znał się trochę na motoryzacji,
    a tutejsze wojsko ma kłopoty z samochodem, więc przyszli poprosić o jego pomoc.
</p>

<p>
    Miałam jedenaście lat jak przed końcem roku szkolnego zabrali nas i wszystkich innych z naszej wsi. W obozie nikt nie
    informował jaki jest dzień, który to miesiąc. Pogoda zmieniła się na tyle szybko, że było bardzo zimno. Dziewczynki i
    kobiety stanowiły większość w grupie, która zamieszkiwała obóz. Zdarzały się poczególne przypadki mężczyzn, ale były to
    dosłownie jednostki. Kobiety miały za zadanie prać żołnierzom mundury, wyprawiać skóry w obozowej garbarni. Dziewczynki
    były kierowane do lepienia cegiełek ze słomy i krowich odchodów, co stanowiło paliwo do ogrzewania domów.
</p>

<p>
    Pilnowały nas zawsze więźniarki. Nie pozwalały siadać na stołkach, na czymkolwiek, co ulżyło by zmęczonym kręgosłupom.
    Krzyczały żeby stać prosto, że wyrabiamy arcyważny placek, którego to przygotowanie powinno być dla nas nobilitujące.
    Wmawiały nam, że jesteśmy jak księżniczki więc powinniśmy stać prosto i wykonywać czynności z gracją.
</p>

<p>
    Już pierwszego dnia pod wieczór nie mogłam ruszyć palcami. Mama masowała mi dłonie próbując je ogrzać, by
    powróciło w nich krążenie. W naszym pokoju mieszkała też inna dziewczynka. Miała na imię Zosia, była w podobnym wieku do
    mnie. Dla niej również ugniatanie placków na stojąco w zimnie było mordęgą. Miałam wrażenie, że ona lepiej to znosi
    niż ja. Była bardziej dzielna, zdyscyplinowana. Nie dawała po sobie poznać, że jest jej ciężko. Dopiero wieczorem w domu
    widziałam jej łzy. Słyszałam jak prosiła swoją mamę, by już nie ogrzewała jej dłoni, bo jakiekolwiek ciepło powoduje ogromny ból,
    którego ona nie może znieść. Usiadłam na podłodze blisko niej. Złapałyśmy się za ręce. Poczułam jej twarde, skostniałe palce.
    Patrzyłyśmy na siebie i mimo otaczającego zimna, to każda z nas odczuwała, że w tej krzywdzie jesteśmy razem i przez to
    jest nam jakoś raźniej. Od tego momentu nie odstępowałyśmy się na krok. Obok siebie stałyśmy na porannych apelach i obok siebie
    kładłyśmy się spać.
</p>

<p>
    Pewnego ranka wkroczyło wojsko do naszego pokoju. Wzięli mamę pod pachę i wyprowadzili na zewnątrz. Tam jeden z
    dowódców powiedział jej coś po czym ona wpadła w ogromny płacz. Wrzask i pisk, który rozniósł się po całym obozie
    był na tyle przerażający, że podobno strażnicy obozowi powychodzili ze swoich posterunków, by sprawdzić co to za hałas.
    Od tego momentu mama była inna. Wciąż jakby nieobecna, wpatrzona w jakiś punkt na horyzoncie. Po pewnym czasie dowiedziałam
    się, że mój brat i tato, który jednak się odnalazł, zostali rozstrzelani za próbę ucieczki.
</p>

<h3 className="text-center mt-3 mb-3"><i>Wyzwolenie</i></h3>
<p>
    W obozie spędziłyśmy około sześć lat. Powiedziano nam o tym w pociągu, który odwoził nas do Polski. Jeden z obecnych
    w nim żołnierzy mówił, że mamy szczęście, że wracamy, bo niewielu miało taką możliwość, niewielu przeżyło ten trudny okres.
    Jak tylko wysiadłyśmy na stacji w Lublinie to od razu usłyszałam wołanie:
</p>

<p>
    - Róża! Stefa! - na stacji stała ciocia Grażyna z wujkiem Piotrem.
</p>

<p>
    Tego samego wieczora byliśmy już w Sopocie. Szum fal rozchodzący się po wybrzeżu, nad którym stał ich dom, był tak bardzo kojący,
    że szybko zasnęłam w świeżo wypranej i przygotwanej dla mnie pościeli. Nim wpadłam w błogi sen usłyszałam tylko jak wujek mówił, że mamę
    musi zobaczyć lekarz.
</p>

<p>
    Poranek przywitał mnie zapachem pieczonego chleba i unoszącym się w całym domu aromatem kawy zbożowej.
    Przy stole czekała na mnie ciocia, a mamy i wujka jeszcze nie było. Słońce przebijało się do wnętrza kuchni.
    Poczułam się tak błogo jak w te poranki w naszym domu, gdy wspólnie siadaliśmy do rodzinnych posiłków. Po pierwszym kęsie
    odezwała się ciocia:
</p>

<p>
    - Twoja mama pojechała z wujkiem do lekarza. Dziś spędzimy dzień razem, może pójdziemy na plażę zbierać muszelki?
    Zawsze to lubiłaś.
</p>

<p>
    Ucieszyłam się słysząc te słowa. Mamie koniecznie była potrzebna pomoc. Po tym jak zabito tatę i Włodka to ja stałam się
    głową naszej dwuosobowej rodziny. Byłam jedyną osobą, która choć trochę mogła się nią zaopiekować. Dużą pomoc dostałyśmy też od
    Zosi i jej mamy. Nie wiem czy one też mogły wrócić. Nie wiem co się z nimi stało.
</p>

<p>
    - Tak ciociu, bardzo chętnie - odpowiedziałam.
</p>

<p>
    Na plaży było pięknie. Mewy tak rozkosznie śpiewały. Wiatr przeczesywał mi włosy. Czułam, że chcę już tu zostać. Nie chciałam
    wracać do Kalinówki. Spędziłyśmy razem z ciocią jeszcze dwa dni, po których zjawił się wujek. Przyszli oboje z ciocią do mojego pokoju
    mówiąc:
</p>

<p>
    - Stefciu, a nie chciałabyś tutaj z nami zostać w Sopocie? Mama jest w bardzo dobrym ośrodku pod Warszawą, gdzie dostanie pomoc.
    Nie może się teraz tobą zajmować, ale my to chętnie zrobimy.
</p>

<p>
    Miałam już prawie osiemnaście lat, rozumiałam niemalże wszystko. Wiedziałam co to oznacza.
</p>

<p>
    - Dobrze wujku, zgadzam się.
</p>

<p>
    Wypowiadając te słowa czułam się jak wtedy w obozie, gdy więźniarki mnie chwaliły za
    dobrze wykonaną pracę. Niby szczęście i ulga, ale w środku olbrzymi ból serca.
</p>

<p>
    Spędziłam w Sopocie całą młodość. Skończyłam liceum pielęgniarskie i pracowałam w tamtejszym szpitalu. Nie wyszlam za mąż.
    Po tych wszystkich przeżyciach, które tak głęboko tkwiły w moim sercu, nie potrafiłam zbudować z nikim żadnej, poważnej relacji.
</p>

<p>
    W wieku pięćdziesięciu dwóch lat otrzymałam propozycję pracy we Wrocławiu. Ciocia z wujkiem już dawno zmarli. W Sopocie poza
    grupą znajomych, a właściwie sąsiadów, nie miałam nikogo. Długo się nie zastanawiając spakowałam swoje najpotrzebniejsze rzeczy i
    wyjechałam.
</p>
<h3 className="text-center mt-3 mb-3"><i>Spotkanie</i></h3>
<p>
    Uciekłem tuż przed tym jak przyszli do naszej celi. Pamiętam te rozmowy z tatą o jego konspiracji.
    Byłem z niego bardzo dumny, że ryzykował bardzo dużo na rzecz wolności ojczyzny. Od dziecka zawsze nam wpajał, że
    wolność kiedyś była w naszym kraju i może wrócić znowu jeśli tylko o nią zawalczymy. My, jako naród.
</p>

<p>
    Taki charakter nie pozwolił mi nigdy spuszczać głowy. Zawsze szedłem dzielnie przez życie. W czasie wywózki wiedziałem,
    że muszę zaopiekować się rodziną na czas nieobecności taty. Potem jak spotkałem go w tym kamieniołomie. Był wykończony, ręcę miał
    tak zniszczone, że skorupa, która pokrywała jego dłonie w kontakcie z kolejnymi kamieniami pękała samoistnie wylewając z siebie
    białą wydzielinę. Szybko wdałem się w bójkę z innym mężczyzną, aby zamknęli mnie w celi w pobliżu taty. Potem to jakoś udało się
    przekonać strażników, by osadzili nas w jednym miejscu.
</p>

<p>
    Ciągłe rozmowy z ojcem o mamie i Stefce pozwalały nam  o nich nie zapomnieć. Rozmawialiśmy o tym latawcu, co to go Stefa chciała
    zabrać do Sopotu. Nie było dnia żeby z jego ust nie zostało wypowiedziane imię Róża. Tato bardzo kochał mamę. Opowiedział mi
    jak się poznali, jak mama na początku nie była nim zainteresowana. Jak musiał o nią walczyć.
</p>

<p>
    W ucieczce pomógł mi jeden ze strażników. Zrobiło mu sie chyba żal jak zobaczył jak źle wygląda ojciec, jaki jest
    wycieńczony, niemalże konający. A ja, dziecko, będąc z nim w jednej celi muszę na to patrzeć. Podobno z Sybiru mieli już nas zawracać, żołnierze czuli, że wszystko
    się już kończy.
</p>

<p>
    Wywieziono nas pociągiem gdzieś na zachód kraju. Miałem osiemnaście lat. Byłem w obcym miejscu, nie znałem nikogo,
    nie wiedziałem dokąd pójść. Inni którzy wrócili z Sybiru mieli tu swoje rodziny albo znajomych.
</p>

<p>
    Dość szybko znalazłem zajęcia w piekarni. Właścicel zauważył we mnie pracowitość i lojalnośc i tym sposobem przepracowałem
    tam kilka lat.
</p>

<p>
    Udało mi się znaleźć mieszkanie po niskiej cenie w starej dzielnicy Wrocławia. Kowale w niedalekiej przyszłości zasłynęły
    dużym zakładem włókienniczym, gdzie przyjąłem się do pracy. Wdychanie różnych chemikaliów i wcześniejsza praca w pyle przy
    wyrabianiu chleba sprawiły, że moje płuca wymagały bacznej obserwacji lekarzy. Dolegliwości z nimi związane umożliwiły mi
    przejście na wcześniejszą emeryturę. Tak wiodłem przez lata życie seniora.
</p>

<p>
    Nie lubiłem tych poniedziałkowych wizyt u pulmonolga. Zawsze kolejki, przepychanki pod przychodnią.
</p>

<p>
    - Gdzie mnie staremu potrzebna jeszcze taka adrenalina? - pomyślałem.
</p>

<p>
    Powoli wszedłem do korytarza. Akurat stało wolne krzesło, na które spokojnie usiadłem. Gwar wśród obecnych tam ludzi
    nie opozwalał skupić myśli.
</p>

<p>
    - Brałem dwie tabletki dziennie tego nowego leku czy trzy? Sam już nie wiem - mówiłem do siebie w myślach.
</p>

<p>
    Powoli zbliżała się godzina przyjmowania przez lekarza. Coraz więcej ludzi pojawiło się przed drzwiami.
    Nagle moim oczom ukazała się znajoma twarz. Dumnie szła przez korytarz. Wyprostowana, pełna gracji jak na swój wiek.
    Zmarszczki nie zmieniły jej tak bardzo.
    Ukłócie w sercu i napływająca fala łez do oczu kazały mi pospiesznie wstać.
</p>

<p>
    To była Stefka.
</p>

      </div>
    )
  }
}

export default (Stefania)
