import React, {Component} from 'react'
import Breadcrumb from '../small-components/Breadcrumb'
import Footer from '../Footer'
import {Link} from 'react-router-dom'
import {  FaUser } from 'react-icons/fa'

function About() {
    return(
        <div className="container">
          <Breadcrumb label={'O mnie'}/>
          <div className="mt-5" style={{fontSize: "20px", lineHeight: "28px"}}>
            <div className="mb-5"><FaUser size={50}/></div>
            <p>Mam na imię Łukasz.</p>
            <p>W wolnych chwilach lubię patrzeć jak dorasta mój synek i <Link to={`/teksty`} style={{color: "white"}}>czasem coś napisać.</Link></p>
          </div>
          <Footer/>
        </div>
    )
}

export default (About)
