import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/bootstrap/bootstrap.min.css'
import './css/bootstrap/cover/cover.css'
import './css/main.css'
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
