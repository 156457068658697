import React, {Component} from 'react'
import Breadcrumb from '../small-components/Breadcrumb'
import Footer from '../Footer'

function Contact() {
    return(
      <div className="container">
        <Breadcrumb label={'Kontakt'}/>
        <div>
          <h4>Chcesz się ze mną skontaktować?</h4>
          <br/>
          <h2><a style={{color: "white", textDecoration: "none"}} href="mailto: info@lube-pisac.pl">
            info@lubie-pisac.pl
          </a></h2>
          </div>
        <Footer/>
      </div>
    )
}

export default (Contact)
