import React, {Component} from 'react'
import Stefania from './stories/Stefania'
import Radiowiec from './stories/Radiowiec'
import Kurier from './stories/Kurier'
import Dyspozytorka from './stories/Dyspozytorka'
import Panwladca from './stories/Panwladca'


class Story extends Component {
  render(){
    return(
      <div className="container" style={{fontSize: "20px", lineHeight: "28px"}}>
          {this.props.url && this.props.url == 'stefania' &&
            <Stefania/>
          }
          {this.props.url && this.props.url == 'radiowiec' &&
            <Radiowiec/>
          }
          {this.props.url && this.props.url == 'kurier' &&
            <Kurier/>
          }
          {this.props.url && this.props.url == 'dyspozytorka' &&
            <Dyspozytorka/>
          }
          {this.props.url && this.props.url == 'pan-wladca' &&
            <Panwladca/>
          }
      </div>
    )
  }
}

export default (Story)
