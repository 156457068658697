import React, {Component} from 'react'

class SpaceBar extends Component {
  render(){
    return(
      <hr style={{color: "#686868", marginBottom: "100px", marginTop: "50px"}}/>
    )
  }
}

export default (SpaceBar)
